import { combineReducers } from "redux";
import { ActionTypes } from "../constants/actionTypes";

// import ChangeNumberReducer from './ChangeNumberReducer'
// import ToDoReducer from './ToDoReducer';
// import Billing_Reducer,{FeeHeadList_Reducer} from './Billing_Reducer';
// import {CSVData_Reducer} from './GenReducer';

// ***********************************************************************************
const initial_Billing = {
  originalFeeHeads: [],
};

export const Billing_Reducer = (state = initial_Billing, action) => {
  switch (action.type) {
    case ActionTypes.OPD_BILLING_GET_FEEHEADS_LIST_MASTER:
      // console.log("action.payload Billing_Reducer===> ", action.payload)
      return { ...state, originalFeeHeads: action.payload };

    default:
      return state;
  }
};

const initialFeeHeadList = {
  feeHeadMain: [],
};

export const FeeHeadList_Reducer = (state = initialFeeHeadList, action) => {
  switch (action.type) {
    case ActionTypes.GET_FEE_HEADS_MASTER_LIST:
      // console.log("action.payload FeeHeadList_Reducer===> ", action.payload)
      return { ...state, feeHeadMain: action.payload };

    default:
      return state;
  }
};

// ***********************************************************************************

const initial_CSV_Data = {
  uploadeddata: [],
};

export const CSVData_Reducer = (state = initial_CSV_Data, action) => {
  switch (action.type) {
    case ActionTypes.ADD_CSV_DATA:
      return { ...state, uploadeddata: action.payload };
    case ActionTypes.REMOVE_CSV_ONE_ROW:
      // console.log("action.payload CSVData_Reducer REMOVE_CSV_ONE_ROW===> ", action.payload)
      return {
        ...state,
        uploadeddata: state.uploadeddata.filter((item) => item.id !== action.payload),
      };

    default:
      return state;
  }
};
// ***********************************************************************************

const initial_OPD = {
  list: [],
};

export const OPD_Reducer = (state = initial_OPD, action) => {
  switch (action.type) {
    // case ActionTypes.ADD_OPD:
    //   return { ...state, list: action.payload };
    case ActionTypes.SET_OPD_LIST:
      return { ...state, list: action.payload };
    // case ActionTypes.GET_OPD_LIST:
    //   return { ...state };
    // case ActionTypes.GET_OPD_LIST_BY_MRNO:
    //   // console.log("action.payload CSVData_Reducer REMOVE_CSV_ONE_ROW===> ", action.payload)
    //   return { ...state, list: state.list.filter((item) => item.mrNo === action.payload) };
    // case ActionTypes.DELETE_OPD:
    //   // console.log("action.payload CSVData_Reducer REMOVE_CSV_ONE_ROW===> ", action.payload)
    //   return { ...state, list: state.list.filter((item) => item.id !== action.payload) };

    default:
      return state;
  }
};

// ***********************************************************************************

// ***********************************************************************************
const initial_hospitalHeader = {
  hospitalName:"RAMESHWAR DASS MEMORIAL HOSPITAL",
  hospitalAddress:"NEAR BUS STAND, BYE PASS ROAD, JAGADHRI - 135003",
  hospitalAddress2:"(HARYANA) INDIA",
  hospitalPhoneNo:"01732-297142, Mob. 8278383095",
};
 
  export const HospitalHeader_Reducer = (state = initial_hospitalHeader, action) => {
    switch (action.type) {
      // case ActionTypes.ADD_OPD:
      //   return { ...state, list: action.payload };
      case ActionTypes.SET_HOSPITAL_HEADER:
        return { ...state, list: action.payload };
      // case ActionTypes.GET_OPD_LIST:
      //   return { ...state };
      // case ActionTypes.GET_OPD_LIST_BY_MRNO:
      //   // console.log("action.payload CSVData_Reducer REMOVE_CSV_ONE_ROW===> ", action.payload)
      //   return { ...state, list: state.list.filter((item) => item.mrNo === action.payload) };
      // case ActionTypes.DELETE_OPD:
      //   // console.log("action.payload CSVData_Reducer REMOVE_CSV_ONE_ROW===> ", action.payload)
      //   return { ...state, list: state.list.filter((item) => item.id !== action.payload) };
  
      default:
        return state;
    }
  };

// ***********************************************************************************

const initial_labHeader = {
  // labName:"KISHAN SINGH MEMORIAL TRUST",
  labName:"RAMESHWAR DASS MEMORIAL HOSPITAL",
  labAddress:"NEAR BUS STAND, BYE PASS ROAD, JAGADHRI - 135003",
  labAddress2:"(HARYANA) INDIA",
  labPhoneNo:"01732-297142, Mob. 8278383095",
};
 
export const LabHeader_Reducer = (state = initial_labHeader, action) => {
  switch (action.type) {
    // case ActionTypes.ADD_OPD:
    //   return { ...state, list: action.payload };
    case ActionTypes.SET_LAB_HEADER:
      return { ...state, list: action.payload }; 
    default:
      return state;
  }
};

// ***********************************************************************************





const initialLabTestListMaster = {
  testData: [],
};

export const LabTestList_Reducer = (state = initialLabTestListMaster, action) => {
  switch (action.type) {
    case ActionTypes.ADD_LAB_TEST_LIST_MASTER_DATA:
      // console.log("action.payload FeeHeadList_Reducer===> ", action.payload)
      return { ...state, testData: action.payload };

    default:
      return state;
  }
};
// ***********************************************************************************
// ***********************************************************************************

const allReducers = combineReducers({
  // ChangeNumberReducer,
  // ToDoReducer,
  Billing_Reducer,
  FeeHeadList_Reducer,
  CSVData_Reducer,
  OPD_Reducer,
  HospitalHeader_Reducer,
  LabHeader_Reducer,
  LabTestList_Reducer,
  //, more function
});

export default allReducers;
