import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import axios from "./../axios/axios";
// import axios from "axios";
import { useHref, useNavigate } from "react-router-dom";
import Notification from "./../components/Notification";
import packageJson from "./../../package.json";

// import { Outlet, NavLink } from "react-router-dom";
// import Navbar from "../components/Navbar";
// import CheckboxesTags from "./Test1/CheckBoxTest";
// import Grid from "@mui/material/Grid";
import Controls from "../components/Controls";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

import { Grid, Container, Typography } from "@mui/material";
import HomeWidget from "../components/HomeWidget";

// import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';

// import { useClearCache } from 'react-clear-cache';

import { useGlobalContext } from "../context/Context";
// import {HtmlToWord} from "./Test1/HtmlToDocxTest"
import { HtmlToWord } from "../utils/HtmlToWord";

// import withClearCache from "../utils/ClearCache";

// const ClearCacheComponent = withClearCache(MainHome);

// function Home() {
//   return <ClearCacheComponent />;
// }

function Home() {
  const [hid] = useState(3);

  const [value, setValue] = useState({
    // totalRegnCount: 0,
    // newOpdCount: 0,
    // oldOpdCount: 0,
    // totalOpdCount: 0,
    labReportPendingCount: 0,
    // labReportCompletedCount: 0,
    // billCount: 0,
    // totalBillAmount: 0,
  });

  // const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

  // const { isLatestVersion, emptyCacheStorage } = useClearCache();

  const navigate = useNavigate();

  const { staffUN, ip, roles, isLogin } = useGlobalContext();

  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
  const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

  // console.log(roles);

  // const refreshToken = useRefreshToken();
  // const [formValues, setFormValues] = useState([]);
  // const [isError, setIsError] = useState("");
  // const [isLatestVersion, setIsLatestVersion] = useState(true);
  // const [pdfdata, setPdfData] = useState(null);

  // let pdf_file;

  // const API = axios.create({
  //   baseURL: "http://localhost:8001/templates/rkJTnK2ce",
  //   //timeout: 30000,
  // });

  // useEffect(() => {
  //   // getApiData();
  // }, []);

  useEffect(() => {
    // const getMetaData = () => {
    //https://dev.to/ammartinwala52/clear-cache-on-build-for-react-apps-1k8j
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        const latestVersionDate = meta.buildDate;
        const currentVersionDate = packageJson.buildDate;

        // console.log("meta.buildDate ===>", meta.buildDate);
        // console.log("packageJson.buildDate ===>", packageJson.buildDate);

        const shouldForceRefresh = buildDateGreaterThan(latestVersionDate, currentVersionDate);
        if (shouldForceRefresh) {
          setIsLatestBuildDate(false);
          refreshCacheAndReload();
        } else {
          setIsLatestBuildDate(true);
          // isLatestBuildDate=true;
        }
      });
  }, []);

  const createBackup = async () => {
    // alert('aaaaaaaaaaaaaaaaaaa');
    try {
      // const uri = "http://localhost:8001/api/report";
      // const uri = "";

      const res = await axios.post("/backup");

      console.log("createBackup AAAAAAAAAAAAAAAAAAAA  ===>", res.data);

      if (res.data) {
        // console.log("res.data  ===>", res.data);
        setNotify({ isOpen: true, message: "Backup Created Successfully", type: "success" });
      }
      // setLoading(false);
    } catch (error) {
      console.error(error);
      // setLoading(false);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
  };

  const buildDateGreaterThan = (latestDate, currentDate) => {
    const momLatestDateTime = dayjs(latestDate);
    const momCurrentDateTime = dayjs(currentDate);
    if (momLatestDateTime.isAfter(momCurrentDateTime)) {
      return true;
    } else {
      return false;
    }
  };
  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  const getHomePageWidgetData = async () => {
    // alert('aaaaaaaaaaaaaaaaaaa');
    try {
      // const uri = "http://localhost:8001/api/report";
      // const uri = "";
      const res = await axios.get(
        `/master/get-home-page-widget-data/?hid=${hid}&startdate=${dayjs()}&enddate=${dayjs()}`
      );

      // console.log("createBackup AAAAAAAAAAAAAAAAAAAA  ===>", res.data);
      if (res.data) {
        console.log("res.data  ===>", res.data);
        setValue(res.data);
        // setNotify({ isOpen: true, message: "Backup Created Successfully", type: "success" });
      }
      // setLoading(false);
    } catch (error) {
      console.error(error);
      // setLoading(false);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
  };

  useEffect(() => {
    getHomePageWidgetData();
  }, []);

  return (
    <>
      <Container maxWidth="xl">
        <h1>Home page</h1> 

         {/* <Controls.Button
                      variant="outlined"
                      text="Get Record"
                      color="primary"
                      onClick={getHomePageWidgetData}
                    ></Controls.Button>  */}
       
        {/* <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 8, lg: 12 }} */}

        {/* https://icon-sets.iconify.design/carbon/ */}
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          direction="row"
          // justifyContent="center"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <HomeWidget title="User Name" color="error" total={staffUN} icon={"basil:login-solid"} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <HomeWidget title="User Role" color="error" total={roles} icon={"carbon:user-role"} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <HomeWidget title="App Version" total={packageJson.version} color="error" icon={"pajamas:doc-versions"} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <HomeWidget
              title="App Build Date"
              total2={dayjs(packageJson.buildDate).format("DD/MMM/YYYY hh:mm a")}
              color="error"
              icon={"fluent-mdl2:date-time"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <HomeWidget title="IP-Address" color="error" total={ip} icon={"mdi:internet-protocol-outline"} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <HomeWidget
              title="Total Registration"
              color="primary"
              tooltips="Show only today record"
              total={value.totalRegnCount}
              icon={"medical-icon:i-registration"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <HomeWidget
              title="New OPDs"
              total={value.newOpdCount}
              tooltips="Show only today record"
              color="info"
              icon={"medical-icon:i-administration"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <HomeWidget title="Old OPDs" tooltips="Show only today record" total={value.oldOpdCount} color="info" icon={"medical-icon:i-first-aid"} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <HomeWidget title="Total OPDs" tooltips="Show only today record" total={value.totalOpdCount} color="info" icon={"medical-icon:i-first-aid"} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <HomeWidget
              title="Lab-Test Pending"
              tooltips="Show all pending report (Test-wise report count)"
              // tooltips="Show only today record (Test-wise report count)"
              total={value.labReportPendingCount}
              color="warning"
              icon={"medical-icon:i-pathology"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <HomeWidget
              title="Lab-Test Completed"
              tooltips="Show only today report (Test-wise report count)"
              total={value.labReportCompletedCount}
              color="warning"
              icon={"medical-icon:i-pathology"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <HomeWidget
              title="Total Bills"
              tooltips="Show only today record"
              total={value.billCount}
              color="success"
              icon={"la:money-bill-alt"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <HomeWidget
              title="Total Bills Amount"
              tooltips="Show only today record"
              total={value.totalBillAmount}
              color="success"
              icon={"fa:inr"}
            />
          </Grid>
 
        </Grid>

        {/* </Box> */}

        {/* </Grid> */}
        {/* <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: 200,
          height: 128,
        },
      }}
    > */}

        {/* <Stack spacing={2}> */}
        {/* <Paper elevation={12}      
      sx={{
        color: "rgb(54, 65, 82)",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: "8px",
        backgroundColor: "rgb(255, 229, 127)",
        overflow: "hidden",
        position: "relative",
        padding:"20px",
      }}
      
      >AAAAAAA</Paper>
      <Paper elevation={12} >AAAAAAA</Paper>
      <Paper elevation={12} >AAAAAAA</Paper>
      <Paper elevation={12} >AAAAAAA</Paper>
       
    </Box> */}

        <div style={{ padding: "30px" }}>
          <table style={{}}>
            <tr>
              <td>
                <div style={{ padding: "10px" }}>
                  API: <span style={{ color: "wheat" }}> {process.env.REACT_APP_API_URL} </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div style={{ padding: "10px" }}>
                  <Controls.Button
                    variant="outlined"
                    text="Click here to Update version"
                    color="primary"
                    onClick={refreshCacheAndReload}
                  ></Controls.Button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div style={{ padding: "10px" }}>
                  {roles == "SUPERADMIN" && (
                    <Controls.Button
                      variant="outlined"
                      text="Backup"
                      color="primary"
                      onClick={createBackup}
                    ></Controls.Button>
                  )}
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div style={{ height: "300px", width: "100%" }} />
        {/* <button onClick={() => refreshToken()}>Check Token</button> */}
        {/* <ImportFeeHeads /> */}
        {/* <iframe src={pdfdata} /> */}
        {/* <iframe src="http://localhost:8001/templates/rkJTnK2ce" frameborder="0" height="500px" width="100%"></iframe> */}
        {/* <object data={`data:application/pdf;base64,${pdfdata}`} type="application/pdf" width="100%" height="100%">
      <p>Alternative text</p>
</object> */}
        <Notification notify={notify} setNotify={setNotify} />
      </Container>
    </>
  );
}

export default Home;
