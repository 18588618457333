import React from 'react'
import Barcode from 'react-barcode';

export default function BarcodeGen(props) {
    const { value, height=30 } = props;
    // console.log('Barcode Value   ===>', value, height)
  return (
    <div>
      <Barcode value={value} height={height} />
    </div>
  )
}

//Options:-
// {
//     width: 2,
//     height: 100,
//     format: "CODE128",
//     displayValue: true,
//     fontOptions: "",
//     font: "monospace",
//     textAlign: "center",
//     textPosition: "bottom",
//     textMargin: 2,
//     fontSize: 20,
//     background: "#ffffff",
//     lineColor: "#000000",
//     margin: 10,
//     marginTop: undefined,
//     marginBottom: undefined,
//     marginLeft: undefined,
//     marginRight: undefined
//   }
