import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import axios from "../../axios/axios";

import PageHeader from "../../components/PageHeader";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import CloseIcon from "@mui/icons-material/Close";

import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Stack } from "@mui/system";

// import Popup from "../../components/Popup";
import DataGrid from "../../components/useDataGrid";
import Controls from "../../components/Controls";
// import ConfirmDialog from "../../components/ConfirmDialog";
import Notification from "../../components/Notification";
// import { MyUseForm, MyForm } from "../../components/useForm";

// import * as GenServices from "../../helper/GenServices";
// import { useSelector, useDispatch } from "react-redux";
// import { addLabTestListMasterData } from "../../redux/actions";

//below value only nested values:-
// const initialValues = {
//   val: "",
//   text: "",
//   rate: 0,
//   isActive: true,
//   staffUN: "",
//   ip: "",
// };

export default function PaymentMode() {

  
  
  //   const dispatch = useDispatch();
  //     const hook_AllTestList = useSelector((state)=> state.LabTestList_Reducer.testData);
  const [hid] = useState(3);
  const [entityName] = useState("PaymentMode");
  const [entity_id, setEntity_id] = useState("");
  const [records, setRecords] = useState([]);
  // const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({ isOpen: false, message: "", rate: "" });
  // const [confirmDialog, setConfirmDialog] = React.useState({
  //   isOpen: false,
  //   isConfirm: null,
  //   title: "title",
  //   subtitle: "subtitle",
  // });

  // const DefaultData = {
  //   hid: hid,
  //   entityName: entityName,
  //   payload: [
  //     {
  //       val: "--",
  //       text: "--",
  //       rate: 0,
  //       isActive: true,
  //       staffUN: "AUTO",
  //       ip: "",
  //     },
  //   ],
  // };

  // const validate = (fieldValues = formValues) => {
  //   let temp = { ...errors };

  //   // console.log("fieldValues =====>", fieldValues.dob);
  //   if ("val" in fieldValues) temp.val = fieldValues.val ? "" : "required.";
  //   if ("rate" in fieldValues) temp.rate = parseInt(fieldValues.rate) >= 0 ? "" : "required.";

  //   setErrors({
  //     ...temp,
  //   });
  //   // console.log("temp===>", temp);
  //   if ((fieldValues = formValues)) return Object.values(temp).every((x) => x == "");
  // };

  // const { formValues, setFormValues, errors, setErrors, handleChange } = MyUseForm(initialValues, true, validate);

  const getRecord = async () => {
    try {
      const res = await axios.get(`/master/getentitydata/?hid=${hid}&entityname=${entityName}`);
      // console.log("getRecord PaymentMode ===>", res.data);
      //   console.log("getRecord by res.status  ===>", res.status);
      if (res.data) {
        setRecords(res.data.payload);
        setEntity_id(res.data._id);
        // dispatch(addLabTestListMasterData(res.data));
        // console.log("res.data ===>", res.data);
      } else {
        addDefaultRecord();
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      //   alert("Errrrrrrrrrrr", error.message);
      setNotify({ isOpen: true, message: error.message, rate: "error" });
    }
    //setLoading(false);
  };

  // function getPaymentMode() {
  //   return ["Cash", "UPI", "NEFT", "Swipe", "Cheque", "DD", "Pay-In-Slip"];
  // }

  const addDefaultRecord = async () => {
    try {
      let paymentMode = ["Cash", "UPI", "NEFT", "Swipe", "Cheque", "DD", "Pay-In-Slip"];
      let res2 = "";
      const DefaultData = {
        hid: hid,
        entityName: entityName,
        payload: [],
      };

      paymentMode.map((item) => {
        DefaultData.payload.push({
          val: item,
          text: item,
          rate: 0,
          isActive: true,
          staffUN: "AUTO",
          ip: "",
        });
      });

      res2 = await axios.post(`/master/addentitymaster/?hid=${hid}`, DefaultData);
      //   console.log("res2.data ===>", res2.data);
      if (res2) {
        setRecords(res2.data.payload);
        setEntity_id(res2.data._id);
      }
      // setLoading(false);
    } catch (error) {
      console.error("add-Default-Record ====> error.message ===>", error.message);
      // setLoading(false);
      //   alert("Errrrrrrrrrrr", error.message);
      //   setNotify({ isOpen: true, message: error.message, rate: "error" });
    }
    //setLoading(false);
  };
  useEffect(() => {
    getRecord();
    //   console.log("AAAAAAAAAAAAAaa", formValues);
    //   console.log("entity_id", entity_id);
  }, []);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // alert('aaaaaaaa')
  //   if (validate()) {
  //     addOrEdit(formValues);
  //   } else {
  //     setNotify({ isOpen: true, message: "Please enter the value in required fields!", rate: "error" });
  //     //  console.log(errors)
  //   }
  // };

  // const resetForm = () => {
  //   getRecord();
  //   setFormValues(initialValues);
  //   setErrors({});
  //   setNotify({ isOpen: false, message: "", rate: "" });
  //   // setMRNoValueForReport("");
  // };

  // const bindData = () => {
  //   resetForm();
  //   // setRecordForEdit(null);
  //   setFormValues(initialValues);
  //   getRecord();
  // };

  const checkbox_Changed = async (e, data) => { 
    // console.log("data checkbox_Changed =========>", data);
    data.isActive = e.target.value;
  
    if (entity_id) {
      //This is for Update and Push new Value in feeHeads Nested Array
      try {
        // const ddd = { feeHeads: [data]};
        const res = await axios.patch(`/master/addoreditnesteddata/${entity_id}`, data); //, queryobj);
        // console.log("axios.patch response===>", res);
        if (res) {
          //bindData();
          getRecord();
          // setNotify({ isOpen: true, message: "Save Successfully", type: "success" });
          // if (data._id) setFormValues(initialValues);
        }
      } catch (error) {
        console.error("error=====>", error);
        setNotify({ isOpen: true, message: error.message, type: "error" });
      }
    } else {
      setNotify({ isOpen: true, message: "Please reset the Form", type: "error" });
    }
    // const queryobj = { params: { mrNo: 1234 } }; //<=== this query only for testing
  };

  // const onDelete = async (id) => {
  //   //if (window.confirm("Do you want to delete this record?")) {
  //   try {
  //     // console.log("axios.patch===>", Patient);
  //     setConfirmDialog({ ...confirmDialog, isOpen: false });
  //   //   const res = await axios.delete(`/master/removehead/${id}`);
  //     const res = await axios.patch(`/master/removehead/${entity_id}`, { _id: id }); //, queryobj);
  //     if (res) {
  //       setNotify({ isOpen: true, message: "Deleted Successfully", rate: "success" });
  //       await getRecord();
  //     }
  //   } catch (error) {
  //     console.error("error=====>", error);
  //     setNotify({ isOpen: true, message: error.message, rate: "error" });
  //   }
  //   // RegnServices.deletePatient(id);
  // };

  // const handleClickDelete = (data) => {
  //   //if (window.confirm("Do you want to delete this record?")) {
  //   // console.log("data.row._id", data)
  //   setConfirmDialog({
  //     isOpen: true,
  //     title: "Are you sure to delete this record?",
  //     subtitle: "You can't undo this operation",
  //     isConfirm: () => {
  //       onDelete(data._id);
  //     },
  //   });
  // };

  // const openInEdit = (data) => {
  //   // console.log("data  aaaaaaa", data);
  //   setFormValues(data);
  // };

  const columns = [
    // { field: "_id", headerName: "_id", width: 230, editable: true },
    { field: "val", headerName: "Value", width: 250 },
    { field: "text", headerName: "Text", width: 250 },
    // { field: "rate", headerName: "Rate", width: 100 },
    // { field: "isActive", headerName: "Active", width: 150 },
    // { field: "ip", headerName: "Terminal IP", width: 150 },
    {
      field: "actions",
      headerName: "Is-Active",
      width: 150,
      sortable: false,
      renderCell: (curObj) => {
        return (
          <>
            <Stack direction="row" spacing={1}> 
              <Controls.Checkbox
                label={curObj.row.isActive.toString()}
                name="isActive"
                value={curObj.row.isActive}
                onChange={(e) => checkbox_Changed(e, curObj.row)} 
              /> 
            </Stack>
          </>
        );
      },
    },
    // { field: "staffUN", headerName: "StaffUN", width: 150 },
  ];

  // const handleChange_Val_Text = (e) => {
  //   const value = e.target.value;
  //   setFormValues((preValue) => ({ ...preValue, val: value }));
  //   setFormValues((preValue) => ({ ...preValue, text: value }));
  // };

  return (
    <div>
      <h2>Add Payment-Mode</h2>

      <DataGrid heading="Records" columns={columns} rows={records} pageSize={50} boxHeight={680} />

      <br></br>

      <Notification notify={notify} setNotify={setNotify} />

      <br></br>
    </div>
  );
}
