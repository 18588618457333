// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, Paper } from '@mui/material';
// utils
// import { fShortenNumber } from '../../../utils/formatNumber';

// import Iconify from './iconify';

// components
import Iconify from './Iconify';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(6),
  height: theme.spacing(6),
  justifyContent: 'center',
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

HomeWidget.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function HomeWidget({ title, total, icon, color = 'primary', sx, total2, tooltips }) {
  return (
    <Paper elevation={12}>
    <Card
      title={tooltips}
      sx={{
        py: 2,
        boxShadow: 0,
        textAlign: 'center',
        backgroundImage:'none',
        color: (theme) => theme.palette[color].dark,        
        // bgcolor: (theme) => theme.palette[color].light,
        // bgcolor: 'blueviolet',
        // backgroundColor: (theme) => theme.palette[color].lighter,
        // bgcolor: (theme) => theme.palette[color].lighter,
        // ...sx,
      }}
      // {...other}
    >
      <StyledIcon
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
              theme.palette[color].dark,
              0.24
            )} 100%)`,
        }}
      >
        <Iconify icon={icon} width={24} height={24} />
      </StyledIcon>

      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>

      {total && <Typography variant="h6">{total}</Typography>}
      {total2 && <Typography variant="h8">{total2}</Typography>}
        
    </Card>
    </Paper>
  );
}
