import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import axios from "../../axios/axios";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Popup from "../../components/Popup";
import { useNavigate } from "react-router-dom";
import { login, logout } from "../../context/Actions";
import { useGlobalContext } from "../../context/Context";
import Notification from "../../components/Notification";

import packageJson from "./../../../package.json";

// import { TextField, Typography, Button } from "@material-ui/core";
// import { connect } from "react-redux";
// import { login } from "../redux/actions/auth";
// import Alert from "../../components/Alert"

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {"Copyright © "}
//       <Link color="inherit" to="https://mui.com/">
//         Your Website
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

const theme = createTheme();

export default function Login() {
  //

  const navigate = useNavigate();

  const [hid] = useState(3);

  const [ip, setIP] = useState("");

  const { isLogin, isLogout, state, dispatch } = useGlobalContext();
  const [notify, setNotify] = React.useState({ isOpen: false, message: "", type: "" });

  const [openPopup, setOpenPopup] = React.useState(true);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const userData = {
      userName: data.get("userName"),
      password: data.get("password"),
    };
    try {
      const res = await axios.post(`/login/?hid=${hid}`, userData);

      // console.log("Res  ========> ", res);

      if (res.data) {
        // console.log("AAAAAAAAAAAAAAAA==>", res.data);
        const token = res.data.token;
        let userData = res.data.userData;

        userData.ip = ip ? ip : "0.0.0.0";

        if (userData.roles.role.includes("ADMIN") || userData.roles.role.includes("SUPERADMIN")) {
          userData.isEditRight = true;
          userData.isDeleteRight = true;
        }

        // console.log("userData", userData);

        // console.log("IP ========>", ip);

        localStorage.setItem("hms-token", JSON.stringify(token));
        // localStorage.setItem('hms-user', JSON.stringify(userData));
        dispatch(login(userData));

        navigate("/");
      } else {
        setNotify({ isOpen: true, message: "Please enter correct Username and Password", type: "error" });
      }
    } catch (error) {
      console.error("error", error);
      setNotify({ isOpen: true, message: "Please enter correct Username and Password", type: "error" });
    }
  };

  const getIPAddress = async () => {
    try {
      // const res = await fetch('https://geolocation-db.com/json/');
      const res = await fetch("https://api.ipify.org/?format=json");
      const data = await res.json();

      // const res = await axios.get("https://api.ipify.org/?format=json");
      if (data) {
        // console.log("res.data.ip", data.ip);
        //return res.data.ip;
        setIP(data.ip);
      }
    } catch (error) {
      console.error("GET IP error =====>", error);
    }
  };

  useEffect(() => {
    // fetchApiData(api);
    setOpenPopup(!isLogin);
    getIPAddress();
  }, []);

  // useEffect(() => {
  //   console.log("IP ipppppppppppppppp==>", ip);
  // }, [ip]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Popup title="Login Form" openPopup={openPopup} setOpenPopup={null}>
          <Container component="div" maxWidth="xs">
            {/* <CssBaseline /> */}
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="userName"
                  label="userName"
                  name="userName"
                  autoComplete="userName"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    {/* <Link to="/logout" variant="body2">
                      Forgot password?
                    </Link> */}
                    App Version: {packageJson.version} <br/>
                    App Build Date: {dayjs(packageJson.buildDate).format("DD/MMM/YYYY hh:mm a")}                      
                    API: <span style={{ color: "blue" }}> {process.env.REACT_APP_API_URL} </span>
               

                  </Grid>
                  {/* <Grid item>
                <Link to="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
                </Grid>
              </Box>
            </Box>
            {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
          </Container>
        </Popup>
      </ThemeProvider>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
