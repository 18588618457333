import * as React from "react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import { Button, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
// import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

import { RenderMenu_Group1, RenderMenu_Group2 } from "./../navigation/RenderNavigation";

// import { mainListItems, secondaryListItems } from "./navbar/MenuListItems";
// import { MenuItem } from "@mui/material";
// import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { logout } from "./../context/Actions";
import { useGlobalContext } from "./../context/Context";
// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

// const mdTheme = createTheme();

export default function DashboardContent(props) {
  const navigate = useNavigate();


  const { userName,userPayload, isLogin, isLogout, state, dispatch } = useGlobalContext();

  //const {children} = props;
  // console.log(props)
  //console.log(children)

  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  // let filterTimeout;
  const showDrawer = () => {
    // clearTimeout(filterTimeout);
    // filterTimeout = setTimeout(() => {
    setOpen(true);
    // }, 500);
  };

  const hideDrawer = () => {
    setOpen(false);
  };

  const handleLogout = (event) => {
    event.preventDefault();
    //console.log(userData);
    dispatch(logout());
    localStorage.removeItem("hms-token");
    navigate("/login");
    console.log("logout ===>", isLogout);
  };

  return (
    // <ThemeProvider theme={mdTheme}>
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        {/* keep right padding when drawer closed */}
        <Toolbar sx={{ pr: "24px", minHeight:"50px !important" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{ marginRight: "36px", ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
           RAMESHWAR DASS MEMORIAL HOSPITAL, JAGADHRI           
          </Typography>

          <Stack direction="row" spacing={2}>
            {/* <Button color="inherit">About us</Button>
            <Button color="inherit">Treatment</Button>
            <Button color="inherit">Room</Button>
            <Button color="inherit">Contact us</Button> */}
          </Stack>
          <Typography component="h1" variant="h6" color="inherit" noWrap>
            {userPayload.name}
          </Typography>
          <IconButton color="inherit">
            <Badge badgeContent={1} color="secondary">
              <NotificationsIcon />
              {/* <NotificationsNoneIcon /> */}
            </Badge>
          </IconButton>

          <IconButton color="inherit">
            <Badge badgeContent={1} color="secondary">
              <ChatBubbleIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit" onClick={handleLogout}>
            <PowerSettingsNewIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer sx={{ height: "100vh" }} variant="permanent" open={open} onMouseOver={showDrawer} onMouseOut={hideDrawer}>
        <Toolbar sx={{minHeight:"50px !important", display: "flex", alignItems: "center", justifyContent: "flex-end", px: [1] }}>
          <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ pl: 6, flexGrow: 1 }}>
            WebCare
          </Typography>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav" sx={{ marginBottom: "100px" }}>
          {/* <MenuItem primaryText="Inicio" containerElement = {<NavLink to="/opd"/>}/> */}
          {isLogin && <RenderMenu_Group1/>}
          <Divider sx={{ my: 1 }} />
          {isLogin && <RenderMenu_Group2/>}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          // height: "2000px",
          overflow: "auto",
        }}
      >
        <Toolbar />
        {/* Container  maxWidth="lg" */}
        <Container maxWidth="xl" sx={{ mt: 3, mb: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {/* <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}> */}
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column", minHeight: "80vh" }}>
                {/* <Orders /> */}
                {/* <h1>This is Orders</h1> */}
                {props.children}
              </Paper>
            </Grid>

            {/* Chart */}
            {/* <Grid item xs={12} md={8} lg={9}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 240,
                  }}
                >
                  {/ * <Chart /> * /}
                  <h1>This is Chart</h1>
                </Paper>
              </Grid> */}
            {/* Recent Deposits */}
            {/* <Grid item xs={12} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 240,
                  }}
                >
                  {/* <Deposits /> * /}
                  <h1>This is Deposits</h1>
                </Paper>
              </Grid> */}
            {/* Recent Orders */}
          </Grid>
          {/* <Copyright sx={{ pt: 2 }} /> */}
        </Container>
      </Box>
    </Box>
    // </ThemeProvider>
  );
}

// export default function Dashboard(props) {
//   return <DashboardContent props={props}/>;
// }
