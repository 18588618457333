import React, { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import axios from "../../axios/axios";
// import RegistrationForm from "./RegistrationForm";
import PageHeader from "./../../components/PageHeader";
import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import AddIcon from "@mui/icons-material/Add";
// import CloseIcon from "@mui/icons-material/Close";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DataGrid from "../../components/useDataGrid";
import Popup from "../../components/Popup";
import Controls from "../../components/Controls";
import Checkbox from "@mui/material/Checkbox";
import ConfirmDialog from "../../components/ConfirmDialog";
import Notification from "../../components/Notification";
import { Stack } from "@mui/system";
import Grid from "@mui/material/Grid";
import { Typography, Box } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import SaveRoundedIcon from "@mui/icons-material/SaveRounded";

import { InputAdornment } from "@mui/material";
import { MyUseForm, MyForm } from "../../components/useForm";

import * as GenServices from "../../helper/GenServices";
import * as RegnServices from "../../helper/RegnServices";
import { Toolbar } from "@mui/material";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";

import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";

import OPDBillingForm from "./OPDBillingForm";
// import BillingDateUpdateForm from "./BillingDateUpdateForm";
import DirectBillPrint from "./DirectBillPrint";
import { useSelector, useDispatch } from "react-redux";
import { setAllOriginalFeeHeads, setAllFeeHeadsMasterList } from "../../redux/actions";
import { useGlobalContext } from "../../context/Context";

const initialValuesRegn = {
  // _id: "",
  mrNo: "",
  opdNo: "",
  name: "",
  soDo: "S/o.",
  fhName: "",
  gender: "",
  age: 0,
  pMobNo: "",
  // mobileNo: { pMobNo: "" },
  email: "",
  remarks: "",
  zeroBilling: false,
};

const initialValuesForSubmit = {
  hid: "3",
  ref_reg_opd: "",
  billNo: "",
  billDate: null,
  mrNo: 0,
  opdNo: "",
  paidRs: 0,
  paymentMode: "Cash",
  paymentDetail: "",
  status: "Paid",
  billDetail: [
    // {
    //feeHeadType:"",
    //   feeHeadMain: "",
    //   feeHead: "",
    //   rate: 0,
    //   qty: 1,
    //   amount: 0,
    //   discPer: 0,
    //   discAmt: 0,
    //   taxableAmt: 0,
    //   cgstRate: 0,
    //   cgstAmt: 0,
    //   sgstRate: 0,
    //   sgstAmt: 0,
    //   igstRate: 0,
    //   igstAmt: 0,
    //   netAmt: 0,
    //   remarks: "",
    //   fee_head_main_id: "", <== this head used for Add Lab-Test auto
    //   fee_head_nested_id: "", <== this head used for Add Lab-Test auto
    // },
  ],
  staffUN: "",
  ip: "",
};

export default function OPDBilling() {
  const { staffUN, ip, isEditRight, isDeleteRight } = useGlobalContext();

  const dispatch = useDispatch();

  const [hid] = useState(3);

  const [loading, setLoading] = useState(false);

  const [zeroBilling, setZeroBilling] = useState(false);

  // const [originalReportData, setOriginalReportData] = useState([]);
  const [reportData, setReportData] = useState([]);

  const [reportDataForRightTable, setreportDataForRightTable] = useState([]);

  const [searchVal, setSearchVal] = useState("");

  // const hookAllFeeHeads = useSelector((state) => state.FeeHeadList_Reducer.feeHeadMain);

  // const [recordsOPD, setRecordsOPD] = useState([]);
  const [regnValues, setRegnValues] = useState(initialValuesRegn);
  // const [billListByMRNo, setreportDataForRightTable] = useState([]);
  const [mrNoValue, setMRNoValue] = useState("");
  // const [mainHeadType, setMainHeadType] = useState("");
  // const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = React.useState(false);
  // const [selectedValue, setSelectedValue] = React.useState({});
  const [notify, setNotify] = React.useState({ isOpen: false, message: "", type: "" });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    isConfirm: null,
    title: "title",
    subtitle: "subtitle",
  });
  // const [patientTypeValue, setPatientTypeValue] = useState(GenServices.getPatientType());
  const [printForm, setPrintForm] = useState([]);
  const [isPrintDirect, setIsPrintDirect] = React.useState(false);
  // const [mrNoValueForReport, setMRNoValueForReport] = useState("");
  const [openPopupReceipt, setOpenPopupReceipt] = React.useState(false);
  // const [openPopupBillUpdate, setOpenPopupBillUpdate] = React.useState(false);

  // const [isDateUpdated, setIsDateUpdated] = React.useState(false);
  const [dateForEdit, setDateForEdit] = React.useState({ _id: "", billDate: null }); //, isUpdated: false });

  const [errors, setErrors] = useState({});

  const [totalPaidRsDetail, setTotalPaidRsDetail] = useState({
    paidRs: 0,
    paymentMode: "Cash",
    paymentDetail: "",
  });

  // const [dataBottomTable, setDataBottomTable] = useState([]);
  const [tempDataForSubmit, setTempDataForSubmit] = useState([]);
  const [finalDataForSubmit, setFinalDataForSubmit] = useState(initialValuesForSubmit);

  const getAllOriginalFeeHeads = async () => {
    try {
      // const res = await axios.get("/fee/  headsub");
      const res = await axios.get("/fee/headlist"); //getAll <= here Get All MainHead + SubHead
      if (res) {
        dispatch(setAllOriginalFeeHeads(res.data));
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      console.log("fee/headlist   getAllOriginalFeeHeads() res.data ===>  ", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    //setLoading(false);
  };

  useEffect(() => {
    getAllOriginalFeeHeads();
    // getMainHeads();
  }, []);

  const handleSubmitCharges = async (e) => {
    e.preventDefault();
    // console.log("finalDataForSubmit ===>", finalDataForSubmit);
    // console.log("totalPaidRsDetail ===>", totalPaidRsDetail);

    if (finalDataForSubmit.billDetail.length >= 1) {
      try {
        if (totalPaidRsDetail.paymentMode !== "0") {
          // console.log("axios Insert Start===>", Staff);
          setLoading(true);
          const res = await axios.post("/fee/opdbill", finalDataForSubmit);
          // console.log("axios Insert response===>", res);
          if (res) {
            bindData();
            setTempDataForSubmit([]);
            setNotify({ isOpen: true, message: "Saved Successfully", type: "success" });
            setLoading(false);
          }
        } else {
          setNotify({
            isOpen: true,
            message: "Please select the PaymentMode!",
            type: "error",
          });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("error=====>", error);
        setNotify({ isOpen: true, message: error.message, type: "error" });
      }
    } else {
      setLoading(false);
      setNotify({
        isOpen: true,
        message: "Please add the Charges!",
        type: "error",
      });
    }
  };

  useEffect(() => {
    // setFinalDataForSubmit(initialValuesForSubmit);
    let nestedArray = tempDataForSubmit.map((x) => {
      //"ref_fee_headMain.feeHeadMain",
      const {
        feeHeadType,
        feeHeadMain,
        feeHead,
        rate,
        qty,
        amount,
        discPer,
        discAmt,
        taxableAmt,
        cgstRate,
        cgstAmt,
        sgstRate,
        sgstAmt,
        igstRate,
        igstAmt,
        netAmt,
        remarks,
        fee_head_main_id,
        fee_head_nested_id,
        staffUN,
        ip,
      } = { ...x };
      // const { ref_fee_headMain, feeHead, rate, qty, amount} = {...x};
      // const {feeHeadMain} = {...ref_fee_headMain}
      // const { feeHeadMain } = { ...x.ref_fee_headMain };
      // return ( { feeHeadMain, feeHead, rate, qty, amount} );
      return {
        feeHeadType,
        feeHeadMain,
        feeHead,
        rate,
        qty,
        amount,
        discPer,
        discAmt,
        taxableAmt,
        cgstRate,
        cgstAmt,
        sgstRate,
        sgstAmt,
        igstRate,
        igstAmt,
        netAmt,
        remarks,
        fee_head_main_id,
        fee_head_nested_id,
        staffUN,
        ip,
      };
    }, {});

    setFinalDataForSubmit((prevValue) => ({ ...prevValue, ref_reg_opd: regnValues._id }));
    setFinalDataForSubmit((prevValue) => ({ ...prevValue, mrNo: regnValues.mrNo }));
    setFinalDataForSubmit((prevValue) => ({ ...prevValue, opdNo: regnValues.opdNo }));
    // setFinalDataForSubmit((prevValue) => ({ ...prevValue, feeHeadType: mainHeadType}));
    // setFinalDataForSubmit((preValues) => {
    //   return { ...preValues, paidRs: totalPaidRsDetail.find((element) => element.text == "paidRs").paidRs };
    // });

    setFinalDataForSubmit((prevValue) => ({ ...prevValue, paidRs: totalPaidRsDetail.paidRs }));
    setFinalDataForSubmit((prevValue) => ({ ...prevValue, paymentMode: totalPaidRsDetail.paymentMode }));
    setFinalDataForSubmit((prevValue) => ({ ...prevValue, paymentDetail: totalPaidRsDetail.paymentDetail }));

    setFinalDataForSubmit((prevValue) => ({ ...prevValue, staffUN: staffUN }));
    setFinalDataForSubmit((prevValue) => ({ ...prevValue, ip: ip }));

    setFinalDataForSubmit((prevValue) => ({ ...prevValue, billDetail: nestedArray }));

    // setFinalDataForSubmit((prevValue) => ({ ...prevValue,  billDetail: tempDataForSubmit.map((x) => {
    //   const { feeHead, rate, qty, amount, discPer, discAmt, taxableAmt, cgstRate, cgstAmt, sgstRate, sgstAmt, igstRate, igstAmt, netAmt, remarks, } = { ...x };
    //   const { feeHeadMain } = { ...x.ref_fee_headMain };
    //   return { feeHeadMain, feeHead, rate, qty, amount, discPer, discAmt, taxableAmt, cgstRate, cgstAmt, sgstRate, sgstAmt, igstRate, igstAmt, netAmt, remarks, };
    // })}));
  }, [tempDataForSubmit, totalPaidRsDetail]);

  // useEffect(() => {
  //   console.log("finalDataForSubmit======>", finalDataForSubmit);
  // }, [finalDataForSubmit]);

  // const { formValues, setFormValues, errors, setErrors, handleChange } = MyUseForm(initialValuesOpd, true, validateForm);

  const getOPDBillingRecord = async () => {
    //setLoading(true);
    // validate(initialValues);
    try {
      // const res = await axios.get("/fee/opdbill");

      const res = await axios.get(`/fee/opdbill/report/?hid=${hid}&startdate=${dayjs()}&enddate=${dayjs()}`);

      if (res) {
        // console.log("getOPDBillingRecord  ===>", res.data);
        // setData BottomTable(res.data);
        // setOriginalReportData(res.data);
        setReportData(res.data);
        // console.log("setData BottomTable ===>", res.data);
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      console.error("Error ===>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    //setLoading(false);
  };

  useEffect(() => {
    getOPDBillingRecord();
  }, []);

  const bindData = () => {
    // resetForm();
    // setRecordForEdit(null);
    setOpenPopup(false);
    getOPDBillingRecord();
    getBillListByMRNo();
  };

  // const handleByRightTable = (curItem) => {
  //   setRegnValues({ ...regnValues, opdNo: curItem.opdNo });
  // };

  const resetForm = () => {
    // setFormValues(initialValuesOpd);
    setMRNoValue("");
    setRegnValues(initialValuesRegn);
    // setRecordForEdit(null);
    // setErrors({});
    setNotify({ isOpen: false, message: "", type: "" });
    cancelSearch();
    setLoading(false);
    setreportDataForRightTable([]);
    setSearchVal("");
    // setMRNoValueForReport("");
  };

  const getBillListByMRNo = async () => {
    //setLoading(true);
    // validate(initialValues);
    try {
      if (mrNoValue) {
        // const res = await axios.get(`/fee/opdbill/opdvisitlist/${mrNoValue}`);
        const res = await axios.get(`/fee/opdbill/getallopdbillbymrno/?hid=${hid}&mrNo=${mrNoValue}`);
        //  console.log("getBillListByMRNo  ===>", res);

        if (res) {
          setreportDataForRightTable(res.data);
          // console.log("res.data ===>", res.data)
        }
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      console.error("Error ===>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    //setLoading(false);
  };

  const getDataByMRNo = async () => {
    try {
      // if (recordForEdit === null) {
      if (mrNoValue) {
        const res = await axios.get(`/fee/opdbill/opddatabymrno/?hid=${hid}&mrNo=${mrNoValue}`);
        if (res.data) {
          // console.log("AAAAAAA getDataByMRNo  ========> ", res.data);
          // let data = res.data;
          // console.log("getDataByMRNo  ========> ", data);
          setRegnValues(res.data);
          setZeroBilling(res.data.zeroBilling);

          await getBillListByMRNo();

          // const rrr = res.data.map((item)=>{
          //   console.log("item  ========> ", item);
          // })
          // console.log("getDataByMRNo rrr  ========> ", rrr);
          // setFormValues((preValues) => ({ ...preValues, ref_reg_patient: res.data[0]._id }));
          // setFormValues((preValues) => {
          //   return { ...preValues, mrNo: res.data[0].mrNo };
          // });
        } else {
          setNotify({ isOpen: true, message: "Data not found!", type: "error" });
        }
      } else {
        setNotify({ isOpen: true, message: "Please enter the MRNo!", type: "error" });
      }
      // } else {
      //   setNotify({ isOpen: true, message: "Please reset the form!", type: "error" });
      // }
    } catch (error) {
      console.error("getDataByMRNo Error ===>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
  };

  const handleChangeMRNo = (e) => {
    // if (recordForEdit === null) {
    setErrors({});
    setMRNoValue(e.target.value);
    setRegnValues(initialValuesRegn);
    cancelSearch();
    setLoading(false);
    setreportDataForRightTable([]);
  };

  const ConvertDataToPrintableReceipt = (item) => {
    // console.log("item ====>", item);
    // const { mrNo, opdNo, paidRs, paymentMode, paymentDetail, billNo, billDate, billDetail, staffUN } =  item;
    // const { name, gender, age } = item.ref_reg_opd.ref_reg_patient;
    // const { pMobNo } = item.ref_reg_opd.ref_reg_patient.mobileNo;
    // const { consultant, feeHead, tblDataRows } = item.ref_reg_opd;
    // const { paidRs, paymentMode, paymentDetail } = totalPaidRsDetail;
    // const printData = {
    //   mrNo,
    //   name,
    //   gender,
    //   age,
    //   pMobNo,
    //   opdNo,
    //   billDate,
    //   billNo,
    //   consultant,
    //   paidRs,
    //   paymentMode,
    //   // billHeading,
    //   // feeHead,
    //   paymentDetail,
    //   billDetail,
    //   staffUN,
    // };
    // return printData;
  };

  const handlePrintBill = (item) => {
    // console.log("handlePrintBill ====", item);
    // console.log("handlePrintBill 111111 ====", ConvertDataToPrintableReceipt(item));

    setIsPrintDirect(true);
    // setPrintForm(ConvertDataToPrintableReceipt(item));
    setPrintForm({ ...item });
    setOpenPopupReceipt(true);
  };

  const openPopupForAddCharges = () => {
    if (!regnValues.mrNo || !regnValues.opdNo || !regnValues.name) {
      setNotify({ isOpen: true, message: "Please enter the Patient Detail....", type: "error" });
      return;
    }
    // setRecordForEdit(null);
    setOpenPopup(true);
    setNotify({ isOpen: false, message: "", type: "" });
    setTotalPaidRsDetail({ paidRs: 0, paymentMode: "Cash", paymentDetail: "" });
    setFinalDataForSubmit(initialValuesForSubmit);
  };

  const onDelete = async (billNo) => {
    //if (window.confirm("Do you want to delete this record?")) {
    try {
      const name = regnValues.name;
      setConfirmDialog({ ...confirmDialog, isOpen: false });
      if (name) {
        const res = await axios.delete(
          `/fee/opdbill/delete-one-bill/?hid=${hid}&billNo=${billNo}&staffUN=${staffUN}&ip=${ip}&name=${name}`
        );
        if (res) {
          bindData();
          setNotify({ isOpen: true, message: "Deleted Successfully", type: "success" });
          // getDataByMRNo();
        }
      } else {
        setNotify({ isOpen: true, message: "Please open the patient detail with MRNo.", type: "error" });
      }
    } catch (error) {
      console.error("error=====>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
  };

  const handleClickDelete = (data) => {
    if (isDeleteRight) {
      // console.log(data)
      setConfirmDialog({
        isOpen: true,
        title: "Are you sure to delete this record?",
        subtitle: "You can't undo this operation",
        isConfirm: () => {
          onDelete(data.billNo);
        },
      });
    } else {
      setNotify({ isOpen: true, message: "you do not have the right to delete", type: "error" });
    }
  };

  // const handleBillDateUpd = (data) => {
  //   // alert('aaaaaaaaaaaaaaaaaa')
  //   console.log("data ============>", data);
  //   setOpenPopupBillUpdate(true);
  //   setDateForEdit({_id:data._id, billDate:data.billDate}) //, isUpdated:false})
  // };

  // useEffect(() => {
  //   console.log("dateForEdit.isUpdated =======>", dateForEdit.isUpdated);
  //   bindData();
  //   //setDateForEdit({ id: "", date: null, isUpdated: false })
  // }, [dateForEdit.isUpdated]);

  const columnsForRightTopTable = [
    {
      field: "actions",
      headerName: "Print",
      width: 50,
      sortable: false,
      renderCell: (curItem) => {
        return (
          <>
            <Stack direction="row" spacing={1}>
              <Controls.ActionButton
                color="primary"
                title="Print OPD-Bill Cum Receipt"
                onClick={() => handlePrintBill(curItem.row)}
              >
                <PrintIcon />
              </Controls.ActionButton>
            </Stack>
          </>
        );
      },
    },
    { field: "mrNo", headerName: "MRNo", width: 80 },
    // { field: "billNo", headerName: "BillNo", width: 100 },
    {
      field: "billDate",
      headerName: "Bill Date/No.",
      width: 120,
      // valueGetter: (item) => dayjs(item.row.billDate).format("DD/MMM/YYYY hh:mm a"),
      renderCell: (item) => (
        <div>
          <Typography>{dayjs(item.row.billDate).format("DD/MM/YYYY")}</Typography>
          <Typography color="textSecondary" sx={{ fontSize: 13 }}>
            {item.row.billNo}
          </Typography>
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Name/FName",
      width: 150,
      // valueGetter: (item) => item.row.name,
      renderCell: (item) => (
        <div>
          <Typography>{item.row.name}</Typography>
          <Typography color="textSecondary" sx={{ fontSize: 12 }}>
            {item.row.fhName}
          </Typography>
        </div>
      ),
    },
    {
      field: "paidRs",
      headerName: "PaidRs",
      width: 80,
      // valueGetter: (item) => item.row.name,
      renderCell: (item) => (
        <div>
          <Typography>{item.row.paidRs}</Typography>
          <Typography color="textSecondary">{item.row.paymentMode}</Typography>
        </div>
      ),
    },

    {
      field: "delete",
      headerName: "Del",
      width: 50,
      sortable: false,
      renderCell: (curItem) => {
        return (
          <>
            <Stack direction="row" spacing={1}>
              <Controls.ActionButton
                color="red"
                title="Delete Bill + Lab-Report"
                onClick={() => handleClickDelete(curItem.row)}
              >
                <DeleteForeverIcon />
              </Controls.ActionButton>
            </Stack>
          </>
        );
      },
    },

    // { field: "paidRs", headerName: "PaidRs", width: 80 },
    // { field: "paymentMode", headerName: "P-Mode", width: 80 },

    // { field: "visitNo", headerName: "VisitNo", width: 100 },

    // { field: "Gender/Age", headerName: "Gender/Age", width: 100, valueGetter: (item) => (item.row.gender +"/"+ item.row.age) },
    // { field: "gender", headerName: "Gender", width: 80 },
    { field: "feeHeadList", headerName: "Fee-Head-List", width: 350 },
  ];

  const columnsBillsReportTable = [
    { field: "mrNo", headerName: "MRNo", width: 90 },
    { field: "opdNo", headerName: "OPDNo", width: 100 },
    // const { name, gender, age } = item.ref_reg_opd.ref_reg_patient;
    // const { pMobNo } = item.ref_reg_opd.ref_reg_patient.mobileNo;

    // { field: "name", headerName: "Name", width: 200, valueGetter: (item) => item.row.ref_reg_opd.ref_reg_patient.name },
    {
      field: "name",
      headerName: "Name/FName",
      width: 180,
      // valueGetter: (item) => item.row.name,
      renderCell: (item) => (
        <div>
          <Typography>{item.row.name}</Typography>
          <Typography color="textSecondary" sx={{ fontSize: 12 }}>
            {item.row.fhName}
          </Typography>
        </div>
      ),
    },
    {
      field: "gender",
      headerName: "Age/Sex",
      width: 100,
      valueGetter: (item) =>
        // item.row.ref_reg_opd.ref_reg_patient.gender + "/ " + item.row.ref_reg_opd.ref_reg_patient.age,
        item.row.age + "/" + item.row.gender.slice(0, 1),
    },
    // { field: "name", headerName: "Name", width: 100, valueGetter: (item) => item.row.ref_reg_opd.ref_reg_patient.name },
    { field: "billNo", headerName: "BillNo", width: 120 },
    {
      field: "billDate",
      headerName: "Bill Date",
      width: 120,
      // valueGetter: (item) => dayjs(item.row.billDate).format("DD/MMM/YYYY hh:mm a"),
      renderCell: (curItem) => {
        return (
          <>
            {dayjs(curItem.row.billDate).format("DD/MMM/YYYY hh:mm a")}
            {/* <Controls.ActionButton
              color="primary"
              title="Only for Edit the Bill Date"
              onClick={() => handleBillDateUpd(curItem.row)}
            >
              {/* <ModeEditOutlineIcon /> * /}
              {dayjs(curItem.row.billDate).format("DD/MMM/YYYY hh:mm a")}
            </Controls.ActionButton> */}
          </>
        );
      },
    },
    {
      field: "paidRs",
      headerName: "PaidRs",
      width: 100,
      // valueGetter: (item) => item.row.name,
      renderCell: (item) => (
        <div>
          <Typography>{item.row.paidRs}</Typography>
          <Typography color="textSecondary">{item.row.paymentMode}</Typography>
        </div>
      ),
    },

    // { field: "paidRs", headerName: "PaidRs", width: 100 },
    {
      field: "actions",
      headerName: "Actions",
      width: 80,
      sortable: false,
      renderCell: (curItem) => {
        return (
          <>
            <Stack direction="row" spacing={1}>
              <Controls.ActionButton
                color="primary"
                title="Print OPD-Bill Cum Receipt"
                onClick={() => handlePrintBill(curItem.row)}
              >
                <PrintIcon />
              </Controls.ActionButton>

              {/* <Controls.ActionButton
                color="red"
                title="Delete"
                onClick={() => handleClickDelete(curObj.row)}
              >
                <DeleteForeverIcon />
              </Controls.ActionButton> */}
            </Stack>
          </>
        );
      },
    },
    // { field: "paymentMode", headerName: "P-Mode", width: 100 },

    { field: "feeHeadList", headerName: "Fee-Head-List", width: 350 },
  ];

  // const handleSearch1111 = async (e) => {
  //   const val = e.target.value;
  //   // const str = val || "";
  //   // console.log("BindBottomTable Value========>", val);
  //   setSearchVal(val);
  //   //if (val) {
  //   const filteredRows = search(originalReportData, val);
  //   // console.log("filteredRows =>", filteredRows);
  //   setReportData(filteredRows);
  //   //}
  // };

  // const [fieldNamesForSearch] = useState(["mrNo", "opdNo", "name", "billNo", "paidRs"]);
  //"mobileNo.pMobNo", pAddress.address
  // const [fieldNamesForSearch] = useState(["mobileNo"]); //"mobileNo.pMobNo",

  // function search111(allItems, val) {
  //   return allItems.filter((item) => {
  //     // console.log("Items", item);
  //     return fieldNamesForSearch.some((newItem) => {
  //       if (newItem === "name")
  //         return item.ref_reg_opd.ref_reg_patient.name.toString().toLowerCase().indexOf(val.toLowerCase()) > -1;
  //       else return item[newItem].toString().toLowerCase().indexOf(val.toLowerCase()) > -1;
  //     });
  //   });
  // }

  // const cancelSearch111 = () => {
  //   setSearchVal("");
  //   setReportData(originalReportData);
  // };

  let filterTimeout;

  const handleSearch = async (e) => {
    try {
      clearTimeout(filterTimeout);
      const val = e.target.value;
      setSearchVal(val);
      if (val.length > 1) {
        filterTimeout = setTimeout(async () => {
          const res = await axios.get(`/fee/opdbill/searchbillingdata/?hid=${hid}&searchtext=${val}`);
          if (res.data) {
            // console.log("RRRRRRRRRRRRRRRRR  ========> ", res.data);
            // const selectedOPD = reportData.filter((item) => item.mrNo == mrNoValue);
            // console.log("selectedOPD  ========> ", selectedOPD);
            setreportDataForRightTable(res.data);
          } else {
            setNotify({ isOpen: true, message: "Data not found!", type: "error" });
          }
        }, 500);
      } else {
        setreportDataForRightTable([]);
      }
    } catch (error) {
      console.error("Error handleSearch ====>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
      setLoading(false);
    }

    // const str = val || "";
    // console.log("BindBottomTable Value========>", val);

    //if (val) {
    // const filteredRows = search(reportData, val);
    // console.log("filteredRows =>", filteredRows);
    // setReportData(filteredRows);
    //}
  };

  const cancelSearch = () => {
    setreportDataForRightTable([]);
    setSearchVal("");
    // setReportData(reportData);
  };

  return (
    <>
      <PageHeader
        title="OPD/IPD Billing Service"
        subTitle="SubTitle here"
        icon={<PeopleOutlineRoundedIcon fontSize="large" color="primary" />}
      />

      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            {/* <Typography variant="h6" color="inherit" noWrap sx={{ pb: 1 }}>
              OPD/IPD Bill/Receipt Entry
            </Typography> */}

            <Grid container spacing={2}>
              {/* mrNo */}
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Controls.Input
                  label="MRNo"
                  name="mrNo"
                  value={mrNoValue}
                  onChange={handleChangeMRNo}
                  title="press tab key for get record"
                  onKeyDown={(e) => (e.key === "Tab" ? getDataByMRNo() : "")}
                  // error={errors.mrNo}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setMRNoValue("");
                            setreportDataForRightTable([]);
                            setRegnValues(initialValuesRegn);
                          }}
                          color="primary"
                          sx={{ p: "10px" }}
                          aria-label="directions"
                        >
                          <ClearIcon />
                        </IconButton>
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <IconButton onClick={getDataByMRNo} color="primary" sx={{ p: "10px" }} aria-label="directions">
                          <DirectionsIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}

                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  //       <IconButton onClick={getDataByMRNo} color="primary" sx={{ p: "10px" }} aria-label="directions">
                  //         <DirectionsIcon />
                  //       </IconButton>
                  //     </InputAdornment>
                  //   ),
                  // }}
                />
              </Grid>
              {/* opdNo */}
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Controls.Input
                  label="OPDNo"
                  name="opdNo"
                  value={regnValues.opdNo}
                  // onChange={handleChange}
                  error={errors.opdNo}
                  // style={{background: "rgb(232, 241, 250)"}}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid>
              {/* </Grid>
            
            <Grid container spacing={2}> */}

              {/* <Box width="100%" /> */}
              {/* Name */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Controls.Input
                  label="Full Name"
                  name="name"
                  value={regnValues.name}
                  // onChange={handleChange}
                  error={errors.name}
                  // style={{background: "rgb(232, 241, 250)"}}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid>
              {/* Father/MotherName */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Controls.Input
                  label="Father/Husband Name"
                  name="fhName"
                  value={regnValues.fhName}
                  // onChange={handleChange}
                  error={errors.fhName}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{regnValues.soDo}</InputAdornment>,
                  }}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid>
              {/* Gender/Age */}
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Controls.Input
                  label="Gender/Age"
                  name="gender"
                  value={regnValues.gender + " / " + regnValues.age}
                  // onChange={handleChange}
                  // options={GenServices.getGender()}
                  // error={errors.gender}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid>
              {/* Email */}
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Controls.Input
                  label="Email"
                  name="email"
                  value={regnValues.email}
                  // onChange={handleChange} error={errors.email}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid>
              {/* mobileNo.pMobNo */}
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <Controls.Input
                  label="Mobile Number"
                  name="pMobNo"
                  value={regnValues.pMobNo}
                  // onChange={handleChange}
                  // error={errors.pMobNo}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid>

              {/* zeroBilling */}
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <Controls.Checkbox
                  label="Zero-Billing"
                  name="zeroBilling"
                  value={regnValues.zeroBilling}
                  // onChange={handleChange}
                  // error={errors.zeroBilling}
                />
              </Grid>

              {/* remarks */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Controls.Input
                  label="Remarks"
                  name="remarks"
                  value={regnValues.remarks}
                  // onChange={handleChange}
                  // error={errors.remarks}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid>

              <Divider sx={{ width: "100%", height: 10, m: 0.5 }} orientation="horizontal" />

              {/* Button */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box m={1} display="flex">
                  <span>
                    <Controls.Button
                      text="Reset"
                      onClick={resetForm}
                      // variant="outlined"
                      //variant="outlined"
                      startIcon={<RestartAltIcon />}
                      // color="default"
                    />
                  </span>
                  <span style={{ mx: "10px", flexGrow: 1, textAlign: "center" }}>
                    <Controls.Button
                      text="Add Charges"
                      onClick={openPopupForAddCharges}
                      // variant="outlined"
                      startIcon={<AddIcon />}
                      sx={{ mr: "15px" }}
                      size="medium"
                    />
                  </span>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Controls.Input
                  label="Search"
                  name="search"
                  value={searchVal}
                  onChange={handleSearch}
                  // title="press tab key for get record"
                  // onKeyDown={(e) => (e.key === "Tab" ? getDataByMRNo() : "")}
                  // error={errors.mrNo}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={cancelSearch} color="primary" sx={{ p: "10px" }} aria-label="directions">
                          <ClearIcon />
                        </IconButton>
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions">
                          <SearchIcon />
                          {/* <DirectionsIcon /> */}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            {/* Patient Wise Bill List*/}

            <DataGrid
              // heading="Patient Wise Bill List" in the Right-Top Corner
              columns={columnsForRightTopTable}
              rows={reportDataForRightTable}
              showToolbar={false}
              margin="dense"
            />
          </Grid>
        </Grid>
      </Box>

      <Box>
        {/* maxWidth="el" */}
        <Popup title="Add Charges" openPopup={openPopup} setOpenPopup={setOpenPopup}>
          <OPDBillingForm
            //addOrEdit={addOrEdit}
            // recordForEdit={recordForEdit}
            tempDataForSubmit={tempDataForSubmit}
            setTempDataForSubmit={setTempDataForSubmit}
            handleSubmitCharges={handleSubmitCharges}
            totalPaidRsDetail={totalPaidRsDetail}
            setTotalPaidRsDetail={setTotalPaidRsDetail}
            loading={loading}
            zeroBilling={zeroBilling}
            // setMainHeadType={setMainHeadType}
          />
        </Popup>
      </Box>

      <Box>
        <DataGrid
          // heading="This is Bottom Table showing the Billing Record of Today"
          columns={columnsBillsReportTable}
          rows={reportData}
          pageSize={40}
          boxHeight={680}
          // showToolbar={true}
        />
      </Box>
      <br></br>

      <Popup title="" openPopup={openPopupReceipt} setOpenPopup={setOpenPopupReceipt} isCloseOutside={true}>
        <DirectBillPrint
          // billHeading="OPD Bill - Cum - Receipt"
          billHeading="Receipt"
          // feeHead="OPD Registration Charges"
          {...printForm}
          // isPrintDirect={isPrintDirect}
          setIsPrintDirect={setIsPrintDirect}
        />
      </Popup>

      {/* <Popup title="Bill Date Update" openPopup={openPopupBillUpdate} setOpenPopup={setOpenPopupBillUpdate} maxWidth="xs">
        <BillingDateUpdateForm
        setOpenPopupBillUpdate={setOpenPopupBillUpdate}
        bindData = {bindData}
          // recordForEdit={recordForEdit}
          dateForEdit={dateForEdit}
          setDateForEdit={setDateForEdit}
          // loading={loading}
          // setLoading={setLoading}
          // updateBillingDate={updateBillingDate}
          isEditRight={isEditRight}
        />
      </Popup> */}

      <Notification notify={notify} setNotify={setNotify} />

      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      {/* <div>Note: Only admin can change bill date and click on blue button to change date.</div> */}
    </>
  );
}
