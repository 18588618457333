import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import axios from "../../axios/axios";

import { Box, Toolbar } from "@mui/material";
import PageHeader from "../../components/PageHeader";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import CloseIcon from "@mui/icons-material/Close";

import DataGrid from "../../components/useDataGrid";
import Popup from "../../components/Popup";
import Controls from "../../components/Controls";
import ConfirmDialog from "../../components/ConfirmDialog";
import Notification from "../../components/Notification";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import { Stack } from "@mui/system";
import { MyUseForm, MyForm } from "../../components/useForm";

import * as BillingServices from "../../helper/BillingServices";
import * as LabServices from "../../helper/LabServices";
import * as GenServices from "../../helper/GenServices";
import { useSelector, useDispatch } from "react-redux";
import { addLabTestListMasterData } from "../../redux/actions";

const initialValues = {
  //_id: "",
  hid: "3",
  ref_fee_head_main_id: "",
  ref_fee_head_nested_id: "",
  // feeHeadMID: "",
  mainHeading: "",
  subHeading: "",
  rate: 0,
  isActive: true,
  //   sorting: 1,
  staffUN: "",
  ip: "",
  testList: [],
  tableType: "GENERAL",
};

export default function LabTestListMain() {

  
  
  const [hid] = useState(3);

  const dispatch = useDispatch();
  const hook_AllTestList = useSelector((state) => state.LabTestList_Reducer.testData);

  // console.log("hook_AllTestList==>  ", hook_AllTestList);

  // const [records, setRecords] = useState([]);
  // const [recordForEdit, setRecordForEdit] = useState(null);

  const [subFeeHeadList, setsubFeeHeadList] = useState([]); //<== These heads taken from FeeHeadList Collection

  const [feeHeadSelectedValue, setFeeHeadSelectedValue] = useState("-------");

  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    isConfirm: null,
    title: "title",
    subtitle: "subtitle",
  });

  const validate = (fieldValues = formValues) => {
    let temp = { ...errors };

    // console.log("fieldValues =====>", fieldValues.dob);
    if ("mainHeading" in fieldValues) temp.mainHeading = fieldValues.mainHeading ? "" : "required.";
    if ("subHeading" in fieldValues) temp.subHeading = fieldValues.subHeading ? "" : "required.";
    if ("tableType" in fieldValues) temp.tableType = fieldValues.tableType !== "0" ? "" : "required.";
    if ("ref_fee_head_main_id" in fieldValues)
      temp.ref_fee_head_main_id = fieldValues.ref_fee_head_main_id ? "" : "required.";

    setErrors({
      ...temp,
    });
    // console.log("temp===>", temp);
    if ((fieldValues = formValues)) return Object.values(temp).every((x) => x == "");
  };

  const { formValues, setFormValues, errors, setErrors, handleChange } = MyUseForm(initialValues, true, validate);

  const getRecord = async () => {
    try {
      // const res = await LabServices.GetLabTestList();
      const res = await axios.get(`/lab/testlist/?hid=${hid}`);
      // console.log("getRecord by mainHeading  ===>", res);
      if (res) {
        //   // setRecords(res.data);
        dispatch(addLabTestListMasterData(res.data));
        //   console.log("getRecord res.data ===>", res.data);
        // }
        // setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    //setLoading(false);
  };

  const getSubFeeHeadList = async () => {
    let options = [];
    try {
      options = await BillingServices.getSubHeadingList(hid);
      // console.log("BillingServices.getSubHeadingList ========>", options);
      setsubFeeHeadList(options);
    } catch (error) {
      console.error("getMainHeads", error.message);
      setsubFeeHeadList(options);
    }
  };

  useEffect(() => {
    getSubFeeHeadList();
    setTimeout(() => {
      getRecord();
    }, 1000);
  }, []);

  // useEffect(() => {
  //   console.log("formValues Data ==>", formValues);
  //   // console.log("subHeadings Data ==>", subHeadings);
  // }, [formValues]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // alert('aaaaaaaa')
    if (validate()) {
      console.log("validate ===>", errors);
      addOrEdit(formValues);
    } else {
      setNotify({ isOpen: true, message: "Please enter the value in required fields!", type: "error" });
      //  console.log(errors)
    }
  };

  const resetForm = () => {
    setFormValues(initialValues);
    // setRegnValues(initialValuesRegn);
    // setRecordForEdit(null);
    setErrors({});
    setNotify({ isOpen: false, message: "", type: "" });

    setFeeHeadSelectedValue("-------");
    // setMRNoValueForReport("");
  };

  const bindData = () => {
    resetForm();
    // setRecordForEdit(null);
    setFormValues(initialValues);
    getRecord();
  };

  const addOrEdit = async (data) => {
    // const data = {...formValues};
    // console.log("data addOrEdit =========>", data);
    // const queryobj = { params: { mrNo: 1234 } }; //<=== this query only for testing
    if (data._id) {
      //This is for Update
      // RegnServices.updatePatient(data);
      try {
        // console.log("axios patch Start===>", data);
        const res = await axios.patch(`/lab/testlist/${data._id}`, data); //, queryobj);
        // console.log("axios.patch response===>", res);
        if (res) {
          bindData();
          setNotify({ isOpen: true, message: "Updated Successfully", type: "success" });
        }
      } catch (error) {
        console.error("error=====>", error);
        setNotify({ isOpen: true, message: error.message, type: "error" });
      }
    } else {
      //This is for Create new
      try {
        console.log("axios Insert Start===>", data);
        const res = await axios.post("/lab/testlist", data);
        // console.log("axios Insert response===>", res);
        if (res) {
          bindData();
          setNotify({ isOpen: true, message: "Saved Successfully", type: "success" });
        }
      } catch (error) {
        console.error("error=====>", error);
        setNotify({ isOpen: true, message: error.message, type: "error" });
      }
    }
  };

  const onDelete = async (id) => {
    //if (window.confirm("Do you want to delete this record?")) {
    try {
      // console.log("axios.patch===>", Patient);
      setConfirmDialog({ ...confirmDialog, isOpen: false });
      const res = await axios.delete(`/lab/testlist/${id}`);
      if (res) {
        setNotify({ isOpen: true, message: "Deleted Successfully", type: "success" });
        await getRecord();
      }
    } catch (error) {
      console.error("error=====>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    // RegnServices.deletePatient(id);
  };

  const handleClickDelete = (data) => {
    //if (window.confirm("Do you want to delete this record?")) {
    // console.log("data.row._id", data)
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subtitle: "You can't undo this operation",
      isConfirm: () => {
        onDelete(data._id);
      },
    });
  };

  const openInEdit = (data) => {
    // console.log("data  aaaaaaa", data);
    setFormValues(data);
    // let ttt = hook_AllTestList.find((element) => element.ref_fee_head_nested_id == data.ref_fee_head_nested_id).text;
    //  const ttt = subFeeHeadList.filter((item) => item.ref_fee_head_nested_id === data.ref_fee_head_nested_id);
    // console.log("ZZZZZZZZZZZZZZ ===", ttt);

    let recordIndex = subFeeHeadList.findIndex((x) => x.ref_fee_head_nested_id == data.ref_fee_head_nested_id);

    // console.log("recordIndex ===", recordIndex);

    setFeeHeadSelectedValue(subFeeHeadList[recordIndex]);
  };

  const checkbox_Changed = async (e, data) => {
    console.log("data checkbox_Changed =========>", data);
    data.isActive = e.target.value;

    try {
      // const ddd = { feeHeads: [data]};
      const res = await axios.patch(`/lab/testlist/${data._id}`, data); //, queryobj);
      // console.log("axios.patch response===>", res);
      if (res) {
        //bindData();
        getRecord();
        // setNotify({ isOpen: true, message: "Save Successfully", type: "success" });
        // if (data._id) setFormValues(initialValues);
      }
    } catch (error) {
      console.error("error=====>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }

    // const queryobj = { params: { mrNo: 1234 } }; //<=== this query only for testing
  };

  const NestedFeeHeadName = (ref_fee_head_nested_id) => {
    if (subFeeHeadList) {
      // let recordIndex = subFeeHeadList.findIndex((x) => x.ref_fee_head_nested_id == ref_fee_head_nested_id);
      return subFeeHeadList.map((x) => {
        if (x.ref_fee_head_nested_id == ref_fee_head_nested_id) return x.text;
      });
      //return subFeeHeadList[recordIndex].text;
      //return recordIndex;
    } else {
      return "";
    }
    //console.log("recordIndex ===", recordIndex);
  };

  const columns = [
    {
      field: "isActive",
      headerName: "Is-Active",
      width: 100,
      sortable: false,
      renderCell: (curObj) => {
        return (
          <>
            <Stack direction="row" spacing={1}>
              <Controls.Checkbox
                label={curObj.row.isActive.toString()}
                name="isActive"
                value={curObj.row.isActive}
                onChange={(e) => checkbox_Changed(e, curObj.row)}
              />
            </Stack>
          </>
        );
      },
    },
    // { field: "_id", headerName: "_id", width: 230 },
    { field: "mainHeading", headerName: "Main-Heading", width: 170, editable: true },
    { field: "subHeading", headerName: "Sub-Heading", width: 250, editable: true },
    // { field: "rate", headerName: "Rate", width: 100 },
    // { field: "isActive", headerName: "Active", width: 150 },

    // { field: "staffUN", headerName: "StaffUN", width: 150 },
    // { field: "ip", headerName: "Terminal IP", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      renderCell: (curObj) => {
        return (
          <>
            <Stack direction="row" spacing={1}>
              <Controls.ActionButton color="primary" title="Edit" onClick={() => openInEdit(curObj.row)}>
                <ModeEditOutlineIcon />
              </Controls.ActionButton>
              <Controls.ActionButton color="red" title="Delete" onClick={() => handleClickDelete(curObj.row)}>
                <CloseIcon />
              </Controls.ActionButton>
            </Stack>
          </>
        );
      },
    },
    {
      field: "Fee-Head-Linked",
      headerName: "Fee-Head-Linked",
      width: 400,
      renderCell: (item) => {
        return NestedFeeHeadName(item.row.ref_fee_head_nested_id);
      },
    },
    { field: "tableType", headerName: "Table-Type", width: 100 },
    // {
    //   field: "Fee-Head-Linked",
    //   headerName: "Is-Active",
    //   width: 150,
    //   sortable: false,
    //   renderCell: (curObj) => {
    //     return (
    //       <>
    //       {
    //         NestedFeeHeadName

    //       }

    //       </>
    //     );
    //   },
    // },
  ];

  const handleChange_FeeSubHeading = (e) => {
    // const { name, value } = e.target;
    if (e.target.value != null && e.target.value != "") {
      // console.log("e.target.value.ref_fee_head_main_id ===========>", e.target.value.ref_fee_head_main_id);

      setFormValues((preValue) => ({ ...preValue, ref_fee_head_main_id: e.target.value.ref_fee_head_main_id }));

      setFormValues((preValue) => ({ ...preValue, ref_fee_head_nested_id: e.target.value.ref_fee_head_nested_id }));

      setErrors((preValue) => ({ ...preValue, ref_fee_head_main_id: "" }));

      // if ("ref_fee_head_main_id" in fieldValues) temp.ref_fee_head_main_id = fieldValues.ref_fee_head_main_id ? "" : "required.";

      let recordIndex = subFeeHeadList.findIndex(
        (x) => x.ref_fee_head_nested_id == e.target.value.ref_fee_head_nested_id
      );
      setFeeHeadSelectedValue(subFeeHeadList[recordIndex]);
    } else {
      setFormValues((preValue) => ({ ...preValue, ref_fee_head_main_id: "" }));
      setErrors((preValue) => ({ ...preValue, ref_fee_head_main_id: "required" }));
      setFeeHeadSelectedValue("-------");
    }
    //BindBottomTable(value);
    // BindSubTestList(e.target.value);
  };

  return (
    <div>
      <h2>Add Lab-Test (Main-Heading)</h2>

      <MyForm onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* mainHeading */}
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Controls.Autocomplete
              label="Main-Heading"
              name="mainHeading"
              value={formValues.mainHeading}
              onChange={handleChange}
              options={LabServices.get_Lab_MainHeading()}
              error={errors.mainHeading}
            />
          </Grid>

          {/* subHeading */}
          <Grid item xs={6} sm={4} md={3} lg={3}>
            <Controls.Input
              label="Sub-Heading"
              name="subHeading"
              value={formValues.subHeading}
              onChange={handleChange}
              error={errors.subHeading}
            />
          </Grid>

          {/* ref_fee_head_main_id */}
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Controls.Autocomplete
              label="Fee Head Sub"
              name="ref_fee_head_nested_id"
              // value={(subFeeHeadList.find((element) => element.ref_fee_head_nested_id == formValues.ref_fee_head_nested_id).text)}
              value={feeHeadSelectedValue}
              onChange={handleChange_FeeSubHeading}
              // onChange={(e) => handleChange(e.target.value.val)}
              options={subFeeHeadList}
              error={errors.ref_fee_head_nested_id}
              // title={"FeeHeadSub mapping with Test-Sub-Heading"}
            />
          </Grid>

          {/* tableType */}
          <Grid item xs={6} sm={4} md={2} lg={2}>
            <Controls.DropdownList
              label="Table-Type"
              name="tableType"
              value={formValues.tableType}
              onChange={handleChange}
              options={LabServices.getTableType()}
              error={errors.tableType}
            />
          </Grid>

          {/* isActive */}
          {/* <Grid item xs={6} sm={4} md={3} lg={2}>
            <Controls.Checkbox
              label="Is-Active"
              name="isActive"
              value={formValues.isActive}
              onChange={handleChange}
              // error={errors.isActive}
            />
          </Grid> */}
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">
            <Box style={{ textAlign: "center", marginTop: "20px" }}>
              {/* Button */}
              <Controls.Button
                sx={{ mx: "20px" }}
                text="Save"
                //onClick={() => console.log(formValues)}
                //variant="outlined"
                // subHeading="submit"
                type="submit"
                startIcon={<SaveRoundedIcon />}
              />
              <Controls.Button
                text="Reset"
                onClick={resetForm}
                //variant="outlined"
                startIcon={<RestartAltIcon />}
                // color="default"
              />
            </Box>
          </Grid>
        </Grid>

        {/* <Grid item sm={12}>
          <FormControlLabel control={<Checkbox color="secondary" name="saveCard" value="yes" />}
            label="Remember credit card details for next time"
          />
        </Grid> */}

        {/* <Grid item sm={12} md={3}>
          <FormGroup row>  
            
            <TextField label="Test-Name" variant="standard" placeholder="username" />
            </FormGroup>
        </Grid> */}
      </MyForm>
      <br></br>
      <DataGrid
        heading="Lab-Test-List-Heading"
        columns={columns}
        rows={hook_AllTestList}
        pageSize={50}
        boxHeight={680}
      />

      <br></br>

      <Notification notify={notify} setNotify={setNotify} />

      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      <br></br>
      <div>
        Note:
        <ul>
          <li> Lab-Sub-Heading = Fee-Sub-Head (is mapped) with above table</li>
          <li>Only Active Heading will be added in Billing Service </li>
          <li>BFA =  Body Fluid Analysis</li>
        </ul>
      </div>
    </div>
  );
}
