import React, { useEffect, useRef, useState } from "react";
import axios from "../../axios/axios";
import PrintIcon from "@mui/icons-material/Print";
import Controls from "../../components/Controls";
import dayjs from "dayjs";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";

import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";
import { BorderColor } from "@mui/icons-material";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { HtmlToWord } from "../../utils/HtmlToWord";

export default function OpdRegnFormPrint(props) {
  const [qualification, setQualification] = useState({ qual1: "", qual2: "", qual3: "" });

  // console.log("Print Opd Form ====>", props);

  // const {printForm} = props;

  // console.log("printForm.opdNo ====>", props.opdNo);

  const { isPrintDirect, setIsPrintDirect } = props;
  // console.log("isPrintDirect ====>", isPrintDirect);
  // console.log("final Data for Print ====>", props);
  // return;

  const { mrNo, name, soDo, fhName, gender, age, disease, diseaseHis, pAddress, pMobNo } = props;
  const { address, city, state, country } = pAddress;

  // const { mrNo, name, soDo, fhName, gender, age, disease, diseaseHis } = props.ref_reg_patient;
  // const { address, city, state, country } = props.ref_reg_patient.pAddress;
  // const { pMobNo } = props.ref_reg_patient.mobileNo;
  const {
    hid,
    opdNo,
    opdDate,
    appointmentType,
    depttName,
    staffUN,
    depttAnnualNo,
    consultant,
    tokenNo,
    roomNo,
    unitName,
    paidRs,
    paymentMode,
    validUpto,
    visitNo,
    staffId,
  } = props;

  useEffect(() => {
    getDoctorQualification(hid, staffId);
  }, []);

  const getDoctorQualification = async (hid, staffId) => {
    //setLoading(true);
    // validate(initialValues);
    console.log("getDoctorQualification Starting............", staffId);

    try {
      //This is get from HRM Module
      const res = await axios.get(`/staff/getdoctorqualification/?hid=${hid}&staffId=${staffId}`);
      console.log("getDoctorQualification ===>", res.data);

      const result = res.data.qualification;
      if (result) {
        let data = result.split(";");
        if (data.length === 1) {
          // setQualification((preValue){ qual1: data[0], qual2: data[1] });
          setQualification((preValue) => ({ ...preValue, qual1: data[0] }));
        } else if (data.length === 2) {
          setQualification((preValue) => ({ ...preValue, qual1: data[0] }));
          setQualification((preValue) => ({ ...preValue, qual2: data[1] }));
        } else if (data.length === 3) {
          setQualification((preValue) => ({ ...preValue, qual1: data[0] }));
          setQualification((preValue) => ({ ...preValue, qual2: data[1] }));
          setQualification((preValue) => ({ ...preValue, qual3: data[2] }));
        }

        // setQualification(res.data);
        // console.log("res.data ===>", res.data)
      }
      // setLoading(false);
    } catch (error) {
      console.error("Error in getDoctorQualification ===>", error);

      // setLoading(false);
      // setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    //setLoading(false);
  };

  const handleClose = (value) => {
    setIsPrintDirect && setIsPrintDirect(false);
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    pageStyle: `@media print { 
      @page {        
        size: A4 !important;
        margin-top: 1cm !important;
        margin-bottom: 1cm !important;
        margin-left: 1.5cm !important;
        margin-right: 0.5cm !important; 
      }
    }
    `,
    content: () => componentRef.current,
    documentTitle: ".",
    onPrintError: (error) => console.log(error),
  });

  useEffect(() => {
    isPrintDirect && handlePrint();
    handleClose();
  }, [isPrintDirect]);

  const exportToWord = () => {
    //var htmlData = document.getElementById(element).innerHTML;
    var htmlData = componentRef.current.innerHTML;
    HtmlToWord(htmlData, "OPD-Consultation-Form.docx");
  };

  return (
    // <h1>{props.opdNo}</h1>
    <div>
      <div style={{ position: "fixed", top: "100px", left: "50%" }}>
        <Controls.ActionButton color="primary" title="Export to MS-Word" onClick={exportToWord}>
          <FileDownloadIcon />
        </Controls.ActionButton>
        <Controls.ActionButton color="primary" title="Print OPD Form" onClick={handlePrint}>
          <PrintIcon />
        </Controls.ActionButton>
      </div>
      <table
        ref={componentRef}
        align="center"
        style={{ marginTop: "0px", width: "100%", border: "0", backgroundColor: "White" }}
      >
        <tr>
          <td valign="top">
            <table align="center" style={{ width: "100%", border: "1", backgroundColor: "White" }}>
              <tr>
                <td valign="top">
                  {/* <img src="header.jpg" alt="Header Image" /> */}
                  {/* <div
                    style={{
                      fontWeight: "bold",
                      height: "70px",
                      marginTop: "5px",
                      paddingLeft: "5px",
                      fontSize: "12px",
                    }}
                  > */}
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td colSpan={3}>
                        <div style={{ fontWeight: "bold", textAlign: "right" }}> Ph. 01732-297142, 82783-83095</div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <div>
                          <div style={{ border: "2px black solid", textAlign: "center", backgroundColor: "lightgray" }}>
                            <span style={{ fontSize: "34px", fontWeight: "bold", fontStretch: "extra-expanded" }}>
                              {" "}
                              RAMESHWAR DASS MEMORIAL HOSPITAL{" "}
                            </span>
                          </div>
                          <div style={{ fontWeight: "bold", textAlign: "center" }}>
                            {" "}
                            NEAR BUS STAND, BYE PASS ROAD, JAGADHRI - 135003 (Haryana).{" "}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top" style={{ width: "34%" }}>
                        <div style={{ fontSize: "12px" }}>
                          <table>
                            <tr>
                              <td>
                                <span style={{ fontSize: "20px", fontWeight: "bold" }}>Dr. Parveen Garg </span>{" "}
                              </td>
                            </tr>
                            <tr>
                              <td> M.B.B.S., M.D. (Gen.Medicine) </td>
                            </tr>
                            <tr>
                              <td> Heart & Kidney Specialist </td>
                            </tr>
                            <tr>
                              <td> Ex-Register: Medical College & Hospital, Rohtak </td>
                            </tr>
                            <tr>
                              <td> Medical Examiner, L.I.C of India </td>
                            </tr>
                          </table>
                        </div>
                      </td>
                      <td valign="top" style={{ width: "33%" }}>
                        <div style={{ fontSize: "12px" }}>
                          <table>
                            <tr>
                              <td>
                                <span style={{ fontSize: "20px", fontWeight: "bold" }}>Dr. Pardeep Chopra</span>{" "}
                              </td>
                            </tr>
                            <tr>
                              <td> M.S., F.M.A.S.(EX. H.C.M.S.) </td>
                            </tr>
                            <tr>
                              <td> Laproscopic & General Sugeon</td>
                            </tr>
                          </table>
                        </div>
                      </td>
                      <td valign="top" style={{ width: "33%" }}>
                        <div style={{ fontSize: "12px" }}>
                          <table>
                            <tr>
                              <td>
                                <span style={{ fontSize: "20px", fontWeight: "bold" }}>Dr. (Mrs.) SEEMA GARG</span>{" "}
                              </td>
                            </tr>
                            <tr>
                              <td> B.D.S.</td>
                            </tr>
                            <tr>
                              <td>Dental surgeon</td>
                            </tr>
                            <tr>
                              <td>Ex-Register:dental college, Rohtak</td>
                            </tr>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </table>
                  <hr style={{ fontWeight: "bold", height: "4px", background: "black", margin: "0px" }}></hr>
                  {/* <span className="fontsize18">{consultant} </span> <br />
                    {qualification.qual1} <br />
                    {qualification.qual2} <br />
                    {qualification.qual3} */}
                  {/* </div> */}
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ fontWeight: "bold", textAlign: "left" }}>
                    {/* <div style={{ textAlign: "left", height: "20px", paddingLeft: "20px" }}>
                      <strong>
                        <span>Vital:-</span>
                      </strong>
                    </div> */}
                    {/* <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                      <table style={{ width: "100%", fontSize: "11px" }}>
                        <tr>
                          <td>Weight</td>
                          <td>..............</td>
                          <td>kgs.,</td>
                          <td>Height</td>
                          <td>.............</td>
                          <td>cms,</td>
                          <td>Temp</td>
                          <td>.............</td>
                          <td>°F,</td>
                          <td>Pulse</td>
                          <td>.............</td>
                          <td>/min</td>
                          <td>SPO2</td>
                          <td>.............</td>
                          <td>%,</td>
                          <td>BP(mmHg)</td>
                          <td>L:</td>
                          <td>...............</td>
                          <td>R:</td>
                          <td>...............</td>
                        </tr>
                      </table> */}

                    {/* Weight..............kgs., Height.............cms, Temp.............°F, Pulse............./min,
                      SPO2.............%, BP(mmHg) L: ............... R: ............... */}
                    {/* </div> */}
                    {/* <div>
                      <table style={{ width: "100%", fontSize: "13px" }}>
                        <tr>
                          <td>Pain:</td>
                          <td>Yes ▢</td>
                          <td>No ▢</td>
                          <td>
                            <img src="painscale.png" style={{ height: "40px", width: "300px" }} />
                          </td>
                          <td>Nut. Ass./Screening Required:</td>
                          <td>Yes ▢</td>
                          <td>No ▢</td>
                        </tr>
                      </table>
                    </div> */}
                  </div>

                  <table style={{ width: "100%", height: "100%" }}>
                    <tr>
                      <td
                        valign="top"
                        width="20%"
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          height: "400px",
                          border: "2px solid black",
                          backgroundColor: "lightgray",
                        }}
                      >
                        <div style={{}}>
                          <div
                            style={{
                              fontWeight: "bold",
                              textAlign: "center",
                              backgroundColor: "black",
                              color: "white",
                            }}
                          >
                            FACILITIES
                          </div>
                          <div style={{ padding: "10px" }}>
                            CARDIAC MONITOR <br />
                            VENTILATOR <br />
                            E.C.G. <br />
                            Dental X-Ray <br />
                            Ultrasonic Scalers <br />
                            Lab. Facilities <br />
                            Operation Theatre <br />
                            24 Hours Emergency <br />
                          </div>
                          <div
                            style={{
                              fontWeight: "bold",
                              textAlign: "center",
                              backgroundColor: "black",
                              color: "white",
                            }}
                          >
                            TIMINGS
                          </div>
                          <div style={{ padding: "10px", textAlign: "center", fontSize:"12px" }}>
                            9:00AM to 2:00PM <br />
                            <br />
                            5:00AM to 7:00PM <br />
                            <br />
                            <br />
                            <div style={{ textAlign: "center", padding: "10px", backgroundColor: "white" }}>
                              <p> दूरबीन द्वारा हर प्रकार के ऑपरेशन की सुविधा उपलब्ध है </p>
                            </div>
                            <br />
                            <div style={{ textAlign: "center" }}>
                              Valid for 7 days <br />
                              <hr style={{ margin: "5px" }} />
                              Not Valid for Medical Legal Purposes
                              <hr style={{ margin: "5px" }} />
                              (SUNDAY CLOSED)
                            </div>
                          </div>
                        </div>
                      </td>
                      <td valign="top" width="80%">
                        <div style={{ border: "2px solid #000000", borderRadius: "7px", padding: "5px" }}>
                          <table style={{ width: "100%" }} className="fontsize14">
                            <tr>
                              <td style={{ width: "17%" }}>Patient Name</td>
                              <td style={{ width: "34%", fontWeight: "bold" }} className="fontsize14">
                                : {name}
                              </td>
                              <td style={{ width: "16%" }}>M.R. No.</td>
                              <td style={{ width: "33%", fontWeight: "bold" }} className="fontsize14">
                                : {mrNo}
                              </td>
                            </tr>
                            <tr>
                              <td>Age/Gender</td>
                              <td style={{ fontWeight: "bold" }} className="fontsize14">
                                : {age} / {gender}
                              </td>
                              <td>Date & Time</td>
                              <td style={{ fontWeight: "bold" }} className="fontsize14">
                                : {dayjs(opdDate).format("DD/MM/YYYY")} at {dayjs(opdDate).format("hh:mm a")}
                              </td>
                            </tr>
                            <tr>
                              <td> Address </td>
                              <td style={{ fontWeight: "bold" }} className="fontsize14">
                                : {address} {",  "} {city}{" "}
                              </td>
                              <td>OPD No. </td>
                              <td style={{ fontWeight: "bold" }} className="fontsize14">
                                : {opdNo}
                              </td>
                            </tr>
                            <tr>
                              <td> Mobile No. </td>
                              <td style={{ fontWeight: "bold" }} className="fontsize14">
                                : {pMobNo}{" "}
                              </td>
                              <td>Consult-Type </td>
                              {/* <td>: {visitNo} {" ("}Valid Upto: {dayjs(validUpto).format("DD/MM/YYYY")}{")"}</td> */}
                              <td style={{ fontWeight: "bold" }} className="fontsize14">
                                : {appointmentType}, ₹ {paidRs}/-
                              </td>
                            </tr>
                          </table>
                        </div>

                        <div style={{ fontWeight: "bold", textAlign: "left", paddingLeft: "10px", marginTop: "-20px" }}>
                          <h1> Rx</h1>
                        </div>
                      </td>
                    </tr>
                  </table>
                  <div style={{}}></div>
                </td>
              </tr>
              {/* <tr>
                <td>
                  <p style={{ padding: "0px", color: "black", marginRight: "150px" }}>
                    Note: Not for Medico Legal Purpose{" "}
                  </p>
                </td>
              </tr> */}
            </table>
          </td>
        </tr>
      </table>
    </div>
  );
}
