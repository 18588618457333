import React, { useEffect, useReducer, useState, useRef } from "react";
import dayjs from "dayjs";
import axios from "../../../axios/axios";
import { useReactToPrint } from "react-to-print";

import PageHeader from "../../../components/PageHeader";
import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import Grid from "@mui/material/Grid";
import PrintIcon from "@mui/icons-material/Print";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip"; 

import { InputAdornment, Box, Paper, TableContainer } from "@mui/material";
import DataGrid from "../../../components/useDataGrid";
import Controls from "../../../components/Controls";
import Notification from "../../../components/Notification";  

export default function LogReport() {
  const [loading, setLoading] = useState(false);
  const [reportType, setreportType] = useState([]);

  //   const dispatch = useDispatch();
  // const AllsubHeadings = useSelector((state) => state.subHeadingList_Reducer.mainHeading);

  //   const hook_AllTestList = useSelector((state) => state.LabTestList_Reducer.testData);

  // const records = useSelector((state)=> state.LabTestList_Reducer.testData);
  //console.log("records==>  ", records);

  // const [formValues, setFormValues] = useReducer((state, newState)=>( ({...state, ...newState}), initialValues ));
  //   const [allLabTestList, setAllLabTestList] = useState([]);

  const [hid] = useState(3);

  // const [records, setRecords] = useState([]);
  const [originalReportData, setOriginalReportData] = useState([]);
  const [reportData, setReportData] = useState([]);

  const [isPrintDirect, setIsPrintDirect] = React.useState(false);

  // const [searchVal, setSearchVal] = useState("");

  // const [totalValue, setTotalValue] = useState(0);

  const [total, setTotal] = useState(0);

  // const [formValues, setFormValues] = useState({
  //   startdate: dayjs(),
  //   enddate: dayjs(),
  // });

  const [formValues, setFormValues] = useState({
    startdate: dayjs(),
    enddate: dayjs(),
    level:"info"
  });

  // const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });

  //   const { formValues, setFormValues, errors, setErrors, handleChange } = MyUseForm(initialValues, true, validate);
  // const { errors, setErrors, handleChange } = MyUseForm(initialValues, true, validate);

  const getReport = async () => {
    //setLoading(true);
    // validate(initialValues);

    try {
      //Below report also used in create OPD page
      const res = await axios.get(
        `/log/getLogReport/?hid=${hid}&level=${formValues.level}&reportType=${formValues.reportType}&startdate=${formValues.startdate}&enddate=${formValues.enddate}`
      );
      console.log("getReport  ===>", res.data);

      if (res) {
        setOriginalReportData(res.data);
        setReportData(res.data);
        // setSearchVal("");

        // let totalPaidRs = res.data.reduce((acc=0, obj) => {
        //   console.log(obj.paidRs)
        //   return acc += parseFloat(obj.paidRs);
        // });
        // let totalRs = res.data.reduce((sum, row) => sum + parseFloat(row.paidRs), 0);
        let totalOPD = res.data.length;
        setTotal(totalOPD);

        // console.log("setOriginalReportData ===>", res.data);
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    //setLoading(false);
  };

  useEffect(() => {
    getReport();
  }, []);

  useEffect(() => {
    // console.log("formValues=", formValues);
    getReport();
  }, [formValues]);

  //_id, mrNo, opdNo, visitNo, opdDate, validDays, validUpto, patientType, depttName, depttAnnualNo,reportType, unitName,
  //   billNo, billDate:opdDate, paidRs,
  //   name,soDo, fhName, gender, age, pMobNo, email, pAddress
  const columnsLabReport = [
    {
      field: "timestamp",
      headerName: "Timestamp",
      width: 100,
      valueGetter: (item) => dayjs(item.row.timestamp).format("DD/MM/YYYY hh:mm a"),
    },
    { field: "level", headerName: "Level", width: 70 },
    { field: "message", headerName: "Message", width: 1100 },
    // { field: "meta", headerName: "Meta", width: 80 },
  ];

  const handlePrintDetailReport = () => {
    setIsPrintDirect(true);
    // setPrintForm(res.data);
    // setOpenPopupReceipt(true);
  };

  return (
    <div>
      <PageHeader title="Logs Report" icon={<PeopleOutlineRoundedIcon fontSize="large" color="primary" />} />

      {/* <Paper> */}
      {/* <MyForm onSubmit={handleSubmit}> */}
      <Grid container spacing={2}>
        <Grid item xs={0} sm={0} md={1} lg={1}></Grid>
        {/* billDate */}
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Controls.DatePicker
            label="Start-Date"
            name="startDate"
            value={dayjs(formValues.startdate).format("DD/MMM/YYYY")}
            onChange={(e) => setFormValues({ ...formValues, startdate: e.target.value })}
            // error={errors.billDate}
            // style={{ background: "#e9e9e9" }}
          />
        </Grid>

        {/* sampleDate */}
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Controls.DatePicker
            label="End-Date"
            name="endDate"
            value={dayjs(formValues.enddate).format("DD/MMM/YYYY")}
            onChange={(e) => setFormValues({ ...formValues, enddate: e.target.value })}
            // error={errors.sampleDate}
            // style={{ background: "#e9e9e9" }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2}>
          <Controls.DropdownList
            label="Report Type"
            name="level"
            value={formValues.level}
            onChange={(e) => {
              setFormValues((preValue) => ({ ...preValue, level: e.target.value }));
            }}
            options={[
              { val: "info", text: "info" },
              { val: "error", text: "error" },
              { val: "all", text: "all" },
            ]}
          />
        </Grid>

         
        {/* sampleDate */}
        <Grid item xs={2} sm={2} md={2} lg={1}>
          <Controls.Button variant="outlined" text="Go" onClick={() => getReport()} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/* <div style={{ textAlign: "center" }}>Total Amount Rs.:- {totalValue}</div> */}

          <Stack spacing={1} alignItems="center">
            <Stack direction="row" spacing={1}> 
              <Chip sx={{ fontSize: "15px" }} label="Total Found Records:-" />
              <Chip sx={{ fontSize: "20px" }} label={total} color="success" />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
     
       

      <div style={{ position: "relative", left: "80%" }}>
        <Controls.ActionButton color="primary" title="Print Report" onClick={handlePrintDetailReport}>
          <PrintIcon />
        </Controls.ActionButton>
      </div>

      <Box sx={{ mb: "50px", mt: "20px" }}>
        <DataGrid
          // heading="OPD Report"
          columns={columnsLabReport}
          rows={reportData}
          showToolbar={true}
          pageSize={20}
          autoPageSize
          boxHeight={400}
        />
      </Box>
       

      <br></br>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
