import React, { useEffect, useState, useRef } from "react";
// import dayjs from "dayjs";
// import axios from "../../axios/axios";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import Controls from "../../components/Controls";
import Notification from "../../components/Notification";
// import { Stack } from "@mui/system";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter } from "@mui/material";

import ClipLoader from "react-spinners/ClipLoader";

import * as GenServices from "../../helper/GenServices";
// import Paper from "@material-ui/core/Paper";
// import SearchBar from "material-ui-search-bar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import ReactToPrint from "react-to-print";
// import { useReactToPrint } from "react-to-print";
// import { ComponentToPrint } from './ComponentToPrint';

import * as BillingServices from "../../helper/BillingServices";

// import { MyUseForm, MyForm } from "../../components/useForm";
import { useSelector, useDispatch } from "react-redux";

import { setAllOriginalFeeHeads } from "../../redux/actions";
import { useGlobalContext } from "../../context/Context";

const initialValues = {
  feeHeadMain_id: "0",
};

// const initialFeeHeadMain = {
//   feeHeadMain: { val: "", text: "" },
// };

const initialValuesFeeHead = {
  // _id: "",
  feeHeadType: "",
  feeHeadMain: "",
  feeHead: "",
  rate: 0,
  qty: 1,
  amount: 0,
  discPer: 0,
  discAmt: 0,
  taxableAmt: 0,
  cgstRate: 0,
  cgstAmt: 0,
  sgstRate: 0,
  sgstAmt: 0,
  igstRate: 0,
  igstAmt: 0,
  netAmt: 0,
  // remarks: "",
  isAmtEditable: false,
  // isActive: true,
  staffUN: "",
  ip: "",
  fee_head_main_id: "", //<== this head used for Add Lab-Test auto
  fee_head_nested_id: "", //<== this head used for Add Lab-Test auto
};

export default function OPDBillingForm(props) {
  const { staffUN, ip, isEditRight, isDeleteRight } = useGlobalContext();

  // const { addOrEdit, recordForEdit } = props;
  // console.log("props ===>", props);
  // const theme = createTheme();
  const originalAllRows = useSelector((state) => state.Billing_Reducer.originalFeeHeads);
  // console.log("originalAllRows ===>", originalAllRows);
  // const dispatch = useDispatch();
  const {
    tempDataForSubmit,
    setTempDataForSubmit,
    handleSubmitCharges,
    totalPaidRsDetail,
    setTotalPaidRsDetail,
    loading,
    zeroBilling,
    // setMainHeadType,
  } = props;

  const [mainFeeHeadList, setMainFeeHeadList] = useState([]);

  const [notify, setNotify] = React.useState({ isOpen: false, message: "", type: "" });

  // const [errors, setErrors] = useState({});
  // const [formValues, setFormValues] = useState([]);

  const [mainFeeHeadObj, setMainFeeHeadObj] = useState({});
  const [completeSubFeeHeadList, setCompleteSubFeeHeadList] = useState([]);
  const [subFeeHeadList, setSubFeeHeadList] = useState([]);
  const [subFeeHeadCount, setSubFeeHeadCount] = useState(0);
  const [subFeeHeadObj, setSubFeeHeadObj] = useState(initialValuesFeeHead);

  const [paymentMode, setPaymentMode] = useState([]);

  // const [isAmtEditable, setIsAmtEditable] = useState(false);

  // const getPaymentMode = async () => {
  //   const rrr = await GenServices.getEntityInnnerData(3, "PaymentMode");
  //   // console.log("Department ------", rrr);
  //   setPaymentMode(rrr);
  //   // return rrr;
  // };

  const getPaymentMode = async () => {
    const rrr = await GenServices.getEntityInnnerData(3, "PaymentMode");
    // console.log("PaymentMode List ------>", rrr);
    const res = rrr.map((item) => {
      return { val: item.val, text: item.text };
    });
    setPaymentMode(res);
    // console.log("PaymentMode List Res------>", res);
    // return rrr;
  };

  const getMainHeads = async () => {
    try {
      // const res = await BillingServices.get--FeeHeadMainList();
      let options = [];
      let allSubHeadList = [];

      // setCompleteSubFeeHeadList([]);
      options.push({ val: "ALL", text: "ALL", type: "" });

      await originalAllRows.map((row) => {
        // let countSubHead = row.feeHeads.length;
        // console.log("row AAAAAAAAAa ====>", row)
        // return { val: row._id, text: row.feeHeadMain, type: row.type };
        row.feeHeads.map((item) => {
          const dd = {
            title: "(" + row.feeHeadMain + ") " + item.feeHead + " (Rs-" + item.rate + ")",
            mainHead_id: row._id,
            feeHeadType: row.type,
            feeHeadMain: row.feeHeadMain,
            fee_head_main_id: row._id,
            fee_head_nested_id: item._id,
            ...item,
          };
          // console.log("row dddddddddddddddddddddddddd ====>", dd)
          allSubHeadList.push(dd);
        });

        options.push({ val: row._id, text: row.feeHeadMain });
        // return  row.feeHeadMain + " (count: " + countSubHead + ")";
      });

      setMainFeeHeadList(options);

      // let sortAllSubHeadList = [...allSubHeadList];
      // sortAllSubHeadList.sort((a, b) => a.feeHead - b.feeHead);
      // const sorted = sortAllSubHeadList.sort((a, b) => a.feeHead - b.feeHead);

      // const sorted = [...allSubHeadList].sort((a, b) => b['feeHead'] < a['feeHead']);
      // const sorted = [...allSubHeadList].sort((a, b) => a.feeHeadMain.localeCompare(b.feeHeadMain) || b.feeHead - a.feeHead);

      // const sorted = allSubHeadList.sort(function (a, b) {
      //   if (a.feeHeadMain === b.feeHeadMain) {
      //     // Price is only important when cities are the same
      //     return b.feeHead - a.feeHead;
      //   }
      //   return a.feeHead > b.feeHead ? 1 : -1;
      // });

      const sorted = allSubHeadList.sort(fieldSorter(["feeHeadMain", "feeHead"]));
      // const sorted = sortAllSubHeadList.sort((a, b) => b["feeHead"] - a["feeHead"]);

      setCompleteSubFeeHeadList(sorted);
      // console.log("sorted ====>", sorted);
    } catch (error) {
      // setLoading(false);
      console.log("getMainHeads() ===>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
  };

  const fieldSorter = (fields) => (a, b) =>
    fields
      .map((o) => {
        let dir = 1;
        if (o[0] === "-") {
          dir = -1;
          o = o.substring(1);
        }
        return a[o] > b[o] ? dir : a[o] < b[o] ? -dir : 0;
      })
      .reduce((p, n) => (p ? p : n), 0);

  useEffect(() => {
    // getAllOriginalFeeHeads();
    getMainHeads();
    getPaymentMode();
  }, []);

  // useEffect(() => {
  //   // getAllOriginalFeeHeads();
  //   // console.log("useEffect   completeSubFeeHeadList ===> ", completeSubFeeHeadList);
  // }, [completeSubFeeHeadList]);

  useEffect(() => {
    getSubHeadFromState();
    // console.log("mainFeeHeadObj 2222 ===>", mainFeeHeadObj);
  }, [mainFeeHeadObj]);

  // useEffect(() => {
  //   // console.log("subFeeHeadObj 4444 ===>", subFeeHeadObj);
  //   // getSubFeeHeads();
  //   // getSubHeadFromState();
  // }, [subFeeHeadObj]);

  const handleChangeFeeHeadMain = (e) => {
    setSubFeeHeadList([]);
    // const { name, value } = e.target;
    // setFormValues({ ...formValues, [name]: value, });
    // setMainFeeHeadObj((preValue) => ({ ...preValue, [name]: value }));
    if (!e.target.value) return;

    if (e.target.value.val !== "ALL") {
      // alert('aaaaaaaaaaaaaaaaaaaaaa')
      // const mainFeeHeaddata = e.target.value;
      setMainFeeHeadObj(e.target.value);
      // console.log("handleChangeFeeHeadMain e.target.value : ", e.target.value);
      // setMainHeadType(mainFeeHeaddata.type); AAAAA
      // console.log("mainFeeHeaddata: ", mainFeeHeaddata);
      // const mainFeeHeadLength = originalAllRows.filter()
      const filteredRows = completeSubFeeHeadList.filter((row) => {
        // return row._id.includes(formValues.feeHeadMain_id);
        return row.mainHead_id == e.target.value.val;
      });
      // console.log("filteredRows: ", filteredRows);
      // setSubFeeHeadCount(filteredRows[0].feeHeads.length);AAA
      setSubFeeHeadCount(filteredRows.length);
    } else {
      // alert("bbbbbbbbbbbbbbbbbb");

      if (completeSubFeeHeadList.length > 0) {
        setSubFeeHeadList(completeSubFeeHeadList);
        setSubFeeHeadCount(completeSubFeeHeadList.length);
      }
    }
  };

  // useEffect(() => {
  //   console.log("subFeeHeadObj ======= ", subFeeHeadObj);
  // }, [subFeeHeadObj]);

  const handleChangeFeeHeadSub = (e) => {
    const { name, value } = e.target; 
    // if (!value) {
    //   setSubFeeHeadObj(initialValuesFeeHead);
    //   return;
    // }

    if (!value) value = 0;

    //if (isNaN(parseFloat(value))) value = 0;

    if (name == "feeHead") {
      if (zeroBilling) {
        value.netAmt = 0;
        setSubFeeHeadObj(value);
      } else {
        setSubFeeHeadObj(value);
      }
    }

    if (name == "rate") {
      setSubFeeHeadObj((preValue) => ({ ...preValue, rate: value }));
      setSubFeeHeadObj((preValue) => ({ ...preValue, qty: 1 }));
      setSubFeeHeadObj((preValue) => ({ ...preValue, amount: value }));
      // setSubFeeHeadObj((preValue) => ({ ...preValue, discPer: 0 }));
      setSubFeeHeadObj((preValue) => ({ ...preValue, discAmt: 0 }));
      setSubFeeHeadObj((preValue) => ({ ...preValue, taxableAmt: value }));

      if (zeroBilling) {
        setSubFeeHeadObj((preValue) => ({ ...preValue, netAmt: 0 }));
      } else {
        setSubFeeHeadObj((preValue) => ({ ...preValue, netAmt: value }));
      }
    } else if (name == "qty") {
      setSubFeeHeadObj((preValue) => ({ ...preValue, qty: value }));

      let amount = parseFloat(subFeeHeadObj.rate) * parseFloat(value);
      setSubFeeHeadObj((preValue) => ({ ...preValue, amount: amount }));

      let discAmt = (amount * parseFloat(subFeeHeadObj.discPer)) / 100;
      setSubFeeHeadObj((preValue) => ({ ...preValue, discAmt: discAmt }));

      setSubFeeHeadObj((preValue) => ({ ...preValue, taxableAmt: amount - discAmt }));

      if (zeroBilling) {
        setSubFeeHeadObj((preValue) => ({ ...preValue, netAmt: 0 }));
      } else {
        setSubFeeHeadObj((preValue) => ({ ...preValue, netAmt: amount - discAmt }));
      }
    }
    // else if (name == "discPer") {
    //   setSubFeeHeadObj((preValue) => ({ ...preValue, discPer: value }));
    //   let discAmt = (parseFloat(subFeeHeadObj.amount) * parseFloat(value)) / 100;
    //   // let amt = parseFloat(subFeeHeadObj.rate) * parseFloat(subFeeHeadObj.qty) - discAmt;
    //   let amount = parseFloat(subFeeHeadObj.amount);
    //   setSubFeeHeadObj((preValue) => ({ ...preValue, discAmt: discAmt }));
    //   // setSubFeeHeadObj((preValue) => ({ ...preValue, amount: amt }));
    //   setSubFeeHeadObj((preValue) => ({ ...preValue, taxableAmt: amount - discAmt }));
    //   if (zeroBilling) {
    //     setSubFeeHeadObj((preValue) => ({ ...preValue, netAmt: 0 }));
    //   } else {
    //     setSubFeeHeadObj((preValue) => ({ ...preValue, netAmt: amount - discAmt }));
    //   }  }
    else if (name == "discPer") {
      setSubFeeHeadObj((preValue) => ({ ...preValue, discPer: value }));
      let discAmt = (parseFloat(subFeeHeadObj.amount) * parseFloat(value)) / 100;
      // let amt = parseFloat(subFeeHeadObj.rate) * parseFloat(subFeeHeadObj.qty) - discAmt;
      let amount = parseFloat(subFeeHeadObj.amount);
      setSubFeeHeadObj((preValue) => ({ ...preValue, discAmt: discAmt }));
      // setSubFeeHeadObj((preValue) => ({ ...preValue, amount: amt }));
      setSubFeeHeadObj((preValue) => ({ ...preValue, taxableAmt: amount - discAmt }));
      if (zeroBilling) {
        setSubFeeHeadObj((preValue) => ({ ...preValue, netAmt: 0 }));
      } else {
        setSubFeeHeadObj((preValue) => ({ ...preValue, netAmt: amount - discAmt }));
      }
    } else if (name == "discAmt") {
      setSubFeeHeadObj((preValue) => ({ ...preValue, discAmt: value }));
      // let discAmt = (parseFloat(subFeeHeadObj.amount) * parseFloat(value)) / 100;
      // let amt = parseFloat(subFeeHeadObj.rate) * parseFloat(subFeeHeadObj.qty) - discAmt;
      let amount = parseFloat(subFeeHeadObj.amount);
      let percentage = ((parseFloat(value) / amount) * 100).toFixed(2);
      // console.log("percentage  ==>", percentage);
      // let differenceAmt = (parseFloat(value) / amount) * 100;
      setSubFeeHeadObj((preValue) => ({ ...preValue, discPer: percentage }));

      // setSubFeeHeadObj((preValue) => ({ ...preValue, discAmt: discAmt }));
      // setSubFeeHeadObj((preValue) => ({ ...preValue, amount: amt }));
      setSubFeeHeadObj((preValue) => ({ ...preValue, taxableAmt: amount - value }));

      if (zeroBilling) {
        setSubFeeHeadObj((preValue) => ({ ...preValue, netAmt: 0 }));
      } else {
        setSubFeeHeadObj((preValue) => ({ ...preValue, netAmt: amount - value }));
      }
    }

    setSubFeeHeadObj((preValue) => ({ ...preValue, staffUN: staffUN }));
    setSubFeeHeadObj((preValue) => ({ ...preValue, ip: ip }));

    // setSubFeeHeadObj((preValue) => ({ ...preValue, feeHeadMain: subFeeHeadObj.feeHeadMain }));
  };

  const getSubHeadFromState = async () => {
    setSubFeeHeadList([]);

    if (mainFeeHeadObj) {
      if (mainFeeHeadObj.val !== "ALL") {
        const filteredRows = completeSubFeeHeadList.filter((row) => {
          // return row._id.includes(formValues.feeHeadMain_id);
          return row.mainHead_id == mainFeeHeadObj.val;
        });
        // console.log("filteredRows ===>", filteredRows);
        // const filteredRows2 = await filteredRows.map((row) => {
        //   return row.feeHeads;
        // });
        if (filteredRows.length > 0) {
          // const filteredRowsSubHeads = await filteredRows[0].feeHeads;AAAA
          // console.log("filteredRows2 ===>", filteredRows2);
          // const filteredRowsSubHeads = filteredRows2[0];
          // console.log("filteredRowsSubHeads ===>", filteredRowsSubHeads);
          // setSubFeeHeadList(filteredRowsSubHeads);AAAA
          setSubFeeHeadList(filteredRows);
          // setOriginalSelectedRows(filteredRowsSubHeads);
          // setRowsForLeftTable(filteredRowsSubHeads);
        }
      } else if (mainFeeHeadObj.val === "ALL") {
        // alert("Last WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW");
        if (completeSubFeeHeadList.length > 0) {
          setSubFeeHeadList(completeSubFeeHeadList);
        }

        // const filteredRows = await originalAllRows.filter((row) => {
        //   // return row._id.includes(formValues.feeHeadMain_id);
        //   return row._id == mainFeeHeadObj.val;
        // });
        // console.log("filteredRows ===>", filteredRows);
        // const filteredRows2 = await filteredRows.map((row) => {
        //   return row.feeHeads;
        // });
        //if (filteredRows.length > 0) {
        // let allSubHeadList = [];

        // await originalAllRows.map((row) => {
        //   // let countSubHead = row.feeHeads.length;
        //   // console.log("row AAAAAAAAAa ====>", row)
        //   // return { val: row._id, text: row.feeHeadMain, type: row.type };
        //   allSubHeadList.push({ val: row._id, text: row.feeHeadMain });
        //   // return  row.feeHeadMain + " (count: " + countSubHead + ")";
        // });

        // const filteredRowsSubHeads = await filteredRows[0].feeHeads;
        // console.log("filteredRows2 ===>", filteredRows2);
        // const filteredRowsSubHeads = filteredRows2[0];
        // console.log("filteredRowsSubHeads ===>", filteredRowsSubHeads);
        // setSubFeeHeadList(filteredRowsSubHeads);
        // setOriginalSelectedRows(filteredRowsSubHeads);
        // setRowsForLeftTable(filteredRowsSubHeads);
        //}
      }
    } else {
      setSubFeeHeadList([]);
    }
  };

  const SumTotalPaidRs = () => {
    try {
      let totalRs = tempDataForSubmit.reduce((sum, row) => sum + parseFloat(row.netAmt), 0);
      // console.log("ADD totalRs: ", totalRs);
      totalRs = totalRs.toFixed(2);
      setTotalPaidRsDetail((preValue) => ({ ...preValue, paidRs: totalRs }));
      // setTotalPaidRsDetail({ ...totalPaidRsDetail, paidRs: totalRs });
    } catch {}
  };

  useEffect(() => {
    // console.log("tempDataForSubmit  ===>", tempDataForSubmit);
    SumTotalPaidRs();
    // getSubFeeHeads();
    // getSubHeadFromState();
  }, [tempDataForSubmit]);

  const handleDeleteFeeHeads = (row) => {
    // let ids = rowsForLeftTable.map((x) => x._id || "");
    // console.log("handlePushFeeHeads ids ===>", ids);
    setTempDataForSubmit(tempDataForSubmit.filter((a) => a._id !== row._id));
    // SumTotalPaidRs();
  };

  const handleAddCharges = () => {
    // console.log("subFeeHeadObj === ", subFeeHeadObj);
    // console.log("tempDataForSubmit === ", tempDataForSubmit);

    let ids = tempDataForSubmit.map((x) => x._id || "");
    // console.log("handlePushFeeHeads ids ===>", ids);
    if (ids.includes(subFeeHeadObj._id) === false) {
      // console.log("subFeeHeadObj === ", subFeeHeadObj);

      if (subFeeHeadObj.feeHead && subFeeHeadObj.feeHeadMain) {
        setTempDataForSubmit((preValue) => [...preValue, { ...subFeeHeadObj }]);
      } else {
        setNotify({ isOpen: true, message: "Please select the Fee-Head!", type: "error" });
      }
    } else {
      setNotify({ isOpen: true, message: "Value already exists!", type: "error" });
    }
  };

  return (
    <>
      {/* <ThemeProvider theme={theme}> */}
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Controls.Autocomplete
              label="Fee Head Main"
              name="feeHeadMain"
              // value={mainFeeHeadObj._abc}
              // onChange={(e, val) => handleChangeAutocomplete(val, "pAddress.state")}
              onChange={handleChangeFeeHeadMain}
              options={mainFeeHeadList}
              // error={errors.state}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Stack direction="row" spacing={2}>
              {/* <Grid item xs={12} sm={6} md={6} lg={6}> */}
              <div> Fee-Head Count: {subFeeHeadCount} </div>
              {/* </Grid> */}
              {/* <Controls.Chip label={subFeeHeadCount} /> */}

              {/* <Grid item xs={12} sm={6} md={6} lg={6}>
              <Controls.Input
              label="rate"
              name="rate"
              value={subFeeHeadObj.rate}
              onChange={handleChangeFeeHeadSub}
              type="number"
              disabled={subFeeHeadObj.isAmtEditable ? false : true}
              // options={mainFeeHeadList}
              // error={errors.headMain}
            /> */}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={5} lg={5}>
            <Controls.Autocomplete
              label="Sub Fee Head"
              name="feeHead"
              // value={mainFeeHeadObj.feeHead}
              // onChange={(e, val) => handleChangeAutocomplete(val, "pAddress.state")}
              onChange={handleChangeFeeHeadSub}
              options={subFeeHeadList}
              // displayText="feeHead"
              // error={errors.state}
            />
          </Grid>

          <Grid item xs={3} sm={3} md={2} lg={1}>
            <Controls.Input
              label="rate"
              name="rate"
              value={subFeeHeadObj.rate}
              onChange={handleChangeFeeHeadSub}
              type="number"
              disabled={subFeeHeadObj.isAmtEditable ? false : true}
              // options={mainFeeHeadList}
              // error={errors.headMain}
            />
          </Grid>

          <Grid item xs={3} sm={3} md={2} lg={1}>
            <Controls.Input
              label="qty"
              name="qty"
              value={subFeeHeadObj.qty}
              onChange={handleChangeFeeHeadSub}
              type="number"
              // error={errors.headMain}
            />
          </Grid>

          <Grid item xs={3} sm={3} md={2} lg={1}>
            <Controls.Input
              label="Disc%"
              name="discPer"
              value={subFeeHeadObj.discPer}
              onChange={handleChangeFeeHeadSub}
              type="number"
              // error={errors.headMain}
              disabled={isEditRight === true ? false : true}
            />
          </Grid>
          <Grid item xs={3} sm={3} md={2} lg={1}>
            <Controls.Input
              label="DiscAmt"
              name="discAmt"
              value={subFeeHeadObj.discAmt}
              onChange={handleChangeFeeHeadSub}
              type="number"
              // error={errors.headMain}
              disabled={isEditRight === true ? false : true}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Controls.Chip label={subFeeHeadObj.netAmt} color="secondary" />
            {/* <Controls.Input
              label="netAmt"
              name="netAmt"
              value={subFeeHeadObj.netAmt}
              // onChange={handleChangeFeeHeadSub}
              // type="number"
              // error={errors.headMain}
            /> */}
          </Grid>
          <Grid item xs={3} sm={3} md={2} lg={1}>
            <Controls.Button
              text="Add"
              variant="outlined"
              onClick={handleAddCharges}
              // variant="outlined"
              // startIcon={<SaveRoundedIcon />}
              size="medium"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            Charges Added
            {/* <Paper> */}
            <TableContainer>
              {/* <Box> */}
              {/* <Table className={classes.Table} aria-label="simple Table"> */}
              <Table aria-label="simple Table">
                <TableHead>
                  <TableRow style={{ background: "#e9e9e9" }}>
                    <TableCell>Fee Head Name</TableCell>
                    <TableCell align="center">Rate</TableCell>
                    <TableCell align="center">Qty</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Disc%</TableCell>
                    <TableCell align="center">DiscAmt</TableCell>
                    <TableCell align="center">TaxableAmt</TableCell>
                    <TableCell align="center">NetAmt</TableCell>
                    <TableCell align="center">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tempDataForSubmit.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell align="left" style={{ width: "200px" }}>
                        {row.feeHead}
                      </TableCell>
                      <TableCell align="center" style={{ width: "80px" }}>
                        {row.rate}
                      </TableCell>
                      <TableCell align="center" style={{ width: "80px" }}>
                        {row.qty}
                      </TableCell>
                      <TableCell align="center" style={{ width: "80px" }}>
                        {row.amount}
                      </TableCell>
                      <TableCell align="center" style={{ width: "80px" }}>
                        {row.discPer}
                      </TableCell>
                      <TableCell align="center" style={{ width: "80px" }}>
                        {row.discAmt}
                      </TableCell>
                      <TableCell align="center" style={{ width: "80px" }}>
                        {row.taxableAmt}
                      </TableCell>
                      <TableCell align="center" style={{ width: "80px" }}>
                        {row.netAmt}
                      </TableCell>
                      <TableCell align="center">
                        <Controls.Button
                          text="Del"
                          onClick={() => handleDeleteFeeHeads(row)}
                          variant="outlined"
                          // startIcon={<AddIcon />}
                          sx={{ mr: "15px" }}
                          size="medium"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={7} style={{ textAlign: "right", fontSize: "large" }}>
                      Total Amount Rs.
                    </TableCell>
                    <TableCell style={{ textAlign: "right", fontSize: "large" }}>
                      <b> {totalPaidRsDetail.paidRs} </b>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
              {/* </Box> */}
            </TableContainer>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box sx={{ textAlign: "center", m: 2 }}>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6} sm={4} md={3} lg={2}>
                  <Controls.DropdownList
                    label="PaymentMode"
                    name="paymentMode"
                    value={totalPaidRsDetail.paymentMode}
                    onChange={(e) => {
                      setTotalPaidRsDetail((curVal) => ({
                        ...curVal,
                        paymentMode: e.target.value,
                      }));
                      if (e.target.value === "Cash") {
                        // setTotalPaidRsDetail({ ...totalPaidRsDetail, paymentDetail: "" });
                        setTotalPaidRsDetail((curVal) => ({ ...curVal, paymentDetail: "" }));
                      }
                    }}
                    options={paymentMode}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  {totalPaidRsDetail.paymentMode !== "Cash" && totalPaidRsDetail.paymentMode !== "0" && (
                    <Controls.Input
                      label="Payment-Detail"
                      name="paymentDetail"
                      value={totalPaidRsDetail.paymentDetail}
                      onChange={(e) => setTotalPaidRsDetail((curVal) => ({ ...curVal, paymentDetail: e.target.value }))}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ textAlign: "center", m: 2 }}>
              <Controls.Button
                text="Save Charges"
                onClick={handleSubmitCharges}
                // variant="outlined"
                startIcon={<SaveRoundedIcon />}
                size="medium"
                disabled={loading}
              />
              <ClipLoader loading={loading} color="#36d7b7" speedMultiplier={1} />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Notification notify={notify} setNotify={setNotify} />
      {/* </ThemeProvider> */}
    </>
  );
}
