import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import axios from "../../axios/axios";

// import StaffForm from "./StaffForm";
import PageHeader from "../../components/PageHeader";
// import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { Box, Toolbar, Typography, InputAdornment } from "@mui/material";

import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";

import { Dialog, Paper, Table, TableBody, TableCell, TableRow } from "@mui/material";
import * as StaffServices from "../../helper/StaffServices";

import Divider from "@mui/material/Divider";

import DataGrid from "../../components/useDataGrid";
import Popup from "../../components/Popup";
import Controls from "../../components/Controls";
import ConfirmDialog from "../../components/ConfirmDialog";
import Notification from "../../components/Notification";
import { Stack } from "@mui/system";
import { MyUseForm, MyForm } from "../../components/useForm";

import { useGlobalContext } from "../../context/Context";

import * as GenServices from "../../helper/GenServices";

// const initialValuesOld = {
//   //_id: "",
//   hid: "3",
//   staffId: "",
//   // name: "",
//   // soDo: "S/o.",
//   // fhName: "",
//   // gender: "",
//   // unitName: "",
//   // deptt: "",
//   // desig: "",
//   // remarks: "",
// };

const initialValues = {
  // hid: "",
  _id: "",
  name: "",
  staffId: "",
  userName: "",
  password: "",
  staffUN: "",
  ip: "",
};

export default function OfficeStaffSecurity() {
  const [hid] = useState(3);

  const { staffUN, ip, isEditRight, isDeleteRight } = useGlobalContext();

  // const [viewValues, setViewValues] = useState(initialValuesOld);
  const [records, setRecords] = useState([]);
  // const [recordForAddEdit, setRecordForAddEdit] = useState(null);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [selectedValue, setSelectedValue] = useState({});

  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    isConfirm: null,
    title: "title",
    subtitle: "subtitle",
  });

  const validate = (fieldValues = formValues) => {
    let temp = { ...errors };
    // if (viewValues._id) {
    if (formValues._id) {
      // console.log("fieldValues =====>", fieldValues);
      let regex = new RegExp(`(?=${formValues.staffId})\\w{7,20}`);

      if ("userName" in fieldValues) {
        let un = fieldValues.userName;

        if (!fieldValues.userName) {
          temp.userName = "userName is required";
        } else if (un.indexOf(" ") >= 1) {
          temp.userName = "BLANK SPACE IS NOT ALLOWED";
        }
        // else if(un === "ADMIN"){temp.userName ="ADMIN UserName IS NOT ALLOWED" }
        else if (un.toUpperCase().indexOf("ADMIN") >= 1) {
          temp.userName = "ADMIN UserName IS NOT ALLOWED";
        } else if (!regex.test(un)) {
          console.log(regex);
          temp.userName = "Please add StaffID + Name (eg. E101RAM";
        } else {
          temp.userName = "";
        }
      }

      if ("password" in fieldValues) {
        let pwd = fieldValues.password;

        if (!fieldValues.password) {
          temp.password = "password is required";
        } else if (pwd.indexOf(" ") >= 1) {
          temp.password = "BLANK SPACE IS NOT ALLOWED";
        } else if (pwd.length <= 3) {
          temp.password = "Password length must be greater than Three digit";
        } else {
          temp.password = "";
        }
      }
    }

    setErrors({
      ...temp,
    });

    // console.log("temp===>", temp);

    if ((fieldValues = formValues)) return Object.values(temp).every((x) => x == "");
  };

  const { formValues, setFormValues, errors, setErrors, handleChange } = MyUseForm(initialValues, true, validate);

  const getStaffRecord = async () => {
    // setLoading(true);
    // validate(initialValues);
    try {
      const res = await axios.get(`/staff/?hid=${hid}`);

      if (res) {
        const staffRecord = res.data.filter((row) => {
          return row.isOfficeStaff == true;
        });

        setRecords(staffRecord);
      }
    } catch (error) {
      // setLoading(false);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    //setLoading(false);
  };

  useEffect(() => {
    getStaffRecord();
  }, []); 

  const bindData = () => { 
    setFormValues(initialValues);
    getStaffRecord();
  };

  const updateData = async () => {
    if (formValues._id) {
      //This is for Update
      try {
        if (isEditRight) {
          const res = await axios.patch(`/staff/updatepassword/${formValues._id}`, formValues);
          if (res) {
            bindData();
            setNotify({ isOpen: true, message: `Updated Successfully`, type: "success" });
            setOpenPopup(false);
          }
        } else {
          setNotify({
            isOpen: true,
            message: "You do not have rights to edit. Please contact the Administrator.!",
            type: "error",
          });
        }
      } catch (error) {
        console.error("error=====>", error);
        setNotify({ isOpen: true, message: error.response.data, type: "error" });
      }
    } else {
      setNotify({
        isOpen: true,
        message: "Data not found!",
        type: "error",
      });
    }
  }; 

  const onDelete = async (data) => {
    try {
      // console.log("onDelete _id =====>", data);

      if (data._id) {
        setConfirmDialog({ ...confirmDialog, isOpen: false });

        // console.log("viewValues._id ===>", viewValues._id);
        // const res = await axios.patch(`/staff/updatepassword/${viewValues._id}`, formValues);
        if (isDeleteRight) {
          const res = await axios.patch(`/staff/deleteUsernamePassword/${data._id}`, data);
          if (res) {
            bindData();
            setNotify({ isOpen: true, message: `Deleted Successfully`, type: "success" });
            //setOpenPopup(false);
          }
        } else {
          setNotify({
            isOpen: true,
            message: "You do not have rights to delete. Please contact the Administrator.!",
            type: "error",
          });
        }
      } else {
        setNotify({
          isOpen: true,
          message: "Data not found!",
          type: "error",
        });
      }

      // updateData('Deleted')
    } catch (error) {
      console.error("error=====>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
  };

  const handleClickDelete = (data) => {
    if (isDeleteRight) {
      const deleteData = {
        _id : data._id,
        staffUN : staffUN,
        ip : ip,
      } 
      setConfirmDialog({
        isOpen: true,
        title: "Are you sure to delete the Username and Password?",
        subtitle: "You can't undo this operation",
        isConfirm: () => {
          onDelete(deleteData);
        },
      });
    } else {
      setNotify({ isOpen: true, message: "you do not have the right to delete", type: "error" });
    }
  }; 

  const showInEdit = (data) => {
    try {
      console.log("showInEdit ====>", data);
      // setViewValues(data);
      // setFormValues()

      // setFormValues(data);
      setFormValues((preValues) => {
        return { ...preValues, _id: data._id };
      });
      setFormValues((preValues) => {
        return { ...preValues, staffId: data.staffId };
      });
      setFormValues((preValues) => {
        return { ...preValues, name: data.name };
      });

      setFormValues((preValues) => {
        return { ...preValues, userName: data.userName };
      });
      setFormValues((preValues) => {
        return { ...preValues, password: data.password };
      });
      setFormValues((preValues) => {
        return { ...preValues, staffUN: staffUN };
      });
      setFormValues((preValues) => {
        return { ...preValues, ip: ip };
      });
      // alert("showInEdit start aaaaaaaaaaaaa");
      setOpenPopup(true);
      // console.log("showInEdit setFormValues ====>", data);
    } catch (error) {
      setNotify({ isOpen: true, message: "try again!", type: "error" });
    }
  };

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      sortable: false,
      renderCell: (curObj) => {
        return (
          <>
            <Stack direction="row" spacing={1}>
              {curObj.row.password && (
                <>
                  <Controls.ActionButton color="primary" title="Edit" onClick={() => showInEdit(curObj.row)}>
                    <ModeEditOutlineIcon />
                  </Controls.ActionButton>
                  <Controls.ActionButton color="red" title="Delete" onClick={() => handleClickDelete(curObj.row)}>
                    <CloseIcon />
                  </Controls.ActionButton>
                </>
              )}

              {!curObj.row.password && (
                <Controls.ActionButton color="" variant="outlined" title="Add" onClick={() => showInEdit(curObj.row)}>
                  <AddIcon />
                </Controls.ActionButton>
              )}
            </Stack>
          </>
        );
      },
    },
    { field: "staffId", headerName: "StaffID", width: 90 },
    { field: "name", headerName: "Full name", width: 150, editable: true },
    { field: "fhName", headerName: "F/H Name", width: 150 },
    { field: "gender", headerName: "Gender", width: 80 },
    { field: "deptt", headerName: "Department", width: 150 },
    { field: "desig", headerName: "Designation", width: 150 },
    { field: "banned", headerName: "Banned", width: 80 },
    { field: "userName", headerName: "UserName", width: 150 },
    { field: "password", headerName: "Password", width: 150 },   
  ];

  //useForm ******************************************** */

  const handleSubmit = () => {
    //e.preventDefault();
    if (formValues._id) {
      if (validate()) {
        // console.log("updateData ===>", formValues);
        updateData();
      } else {
        setNotify({ isOpen: true, message: "Please enter the value in required fields!", type: "error" });
        //  console.log(errors)
      }
    } else {
      setNotify({ isOpen: true, message: "Please select the Staff's record!", type: "error" });
      //  console.log(errors)
    }
  };

  // const resetForm = () => {
  //   setNotify({ isOpen: false, message: "", type: "" });
  //   setViewValues(initialValuesOld);
  //   setFormValues(initialValuesNew);
  //   setErrors({});
  // };

  return (
    <>
      <h2>User Security for Office-Staff</h2>
      {/* <PageHeader title="Staff Roles" subTitle="SubTitle here" icon={<PeopleOutlineRoundedIcon fontSize="large" color="primary" />} /> */}

      {/* <Box m={1} display="flex" justifyContent="flex-end" alignItems="flex-end"></Box> */}

      <Popup title="Create Username and Password" maxWidth="sm" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        {/* <MyForm onSubmit={handleSubmit}> */}
        <Grid container spacing={2}>
          {/* staffId */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Controls.Input
              label="Staff ID"
              name="staffId"
              value={formValues.staffId}
              disabled
              // onChange={handleChangeStaffID}
              // title="press tab key for get record"
              // onKeyDown={(e) => (e.key === "Tab" ? getDataByStaffID() : "")}
              error={errors.staffId}
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              //       <IconButton onClick={getDataByStaffID} color="primary" sx={{ p: "10px" }} aria-label="directions">
              //         <DirectionsIcon />
              //       </IconButton>
              //     </InputAdornment>
              //   ),
              // }}
            />
          </Grid>

          {/* Name */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Controls.Input
              label="Full Name"
              name="name"
              value={formValues.name}
              // onChange={handleChange}
              error={errors.name}
            />
          </Grid>

          <Divider sx={{ width: "100%", height: 10, m: 0.5 }} orientation="horizontal" />

          {/* userName */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Controls.Input
              label="UserName"
              name="userName"
              value={formValues.userName}
              onChange={handleChange}
              error={errors.userName}
            />
          </Grid>

          {/* password */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Controls.Input
              label="Password"
              name="password"
              value={formValues.password}
              onChange={handleChange}
              error={errors.password}
            />
          </Grid>

          {/* Button */}
          <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">
            <Controls.Button
              sx={{ mx: "10px" }}
              text="Save"
              //onClick={() => console.log(viewValues)}
              onClick={handleSubmit}
              //variant="outlined"
              // type="submit"
              startIcon={<SaveRoundedIcon />}
            />
            {/* <Controls.Button
                text="Reset"
                onClick={resetForm}
                //variant="outlined"
                startIcon={<RestartAltIcon />}
                // color="default"
              /> */}
          </Grid>
        </Grid>
        {/* </MyForm> */}
      </Popup>

      <Notification notify={notify} setNotify={setNotify} /> 

      <DataGrid heading="" columns={columns} rows={records} pageSize={50} boxHeight={800} /> 

      <Notification notify={notify} setNotify={setNotify} />

      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      <div>
        Note
        <ul>
          <li>Only ADMIN user can edit or delete records </li>
        </ul>
      </div>
    </>
  );
}
