import React, { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";

import axios from "../../axios/axios";
// import RegistrationForm from "./RegistrationForm";
import PageHeader from "../../components/PageHeader";
import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import AddIcon from "@mui/icons-material/Add";
// import CloseIcon from "@mui/icons-material/Close";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DataGrid from "../../components/useDataGrid";
import Popup from "../../components/Popup";
import Controls from "../../components/Controls";
import Checkbox from "@mui/material/Checkbox";
import ConfirmDialog from "../../components/ConfirmDialog";
import Notification from "../../components/Notification";
import { Stack } from "@mui/system";
import Grid from "@mui/material/Grid";
import { Typography, Box } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ClearIcon from "@mui/icons-material/Clear";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import { InputAdornment } from "@mui/material";
import { MyUseForm, MyForm } from "../../components/useForm";
import ClipLoader from "react-spinners/ClipLoader";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import * as GenServices from "../../helper/GenServices";
// import { getEntityInnnerData } from "../../helper/GenServices";
import * as RegnServices from "../../helper/RegnServices";
import { Toolbar } from "@mui/material";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";

import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";
// import { ComponentToPrint } from './ComponentToPrint';
import OpdRegnFormPrint from "./OpdRegnFormPrint";
import DirectBillPrint from "../Billing/DirectBillPrint";
import { get_OPD_List, set_OPD_List } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";

import { useGlobalContext } from "../../context/Context";

const initialValuesRegn = {
  _id: "",
  mrNo: "",
  regNo: "",
  name: "",
  soDo: "S/o.",
  fhName: "",
  gender: "",
  age: "0Years",
  disease: "",
  mobileNo: { pMobNo: "" },
  email: "",
};

const initialValuesOpd = {
  // _id:"",
  hid: "3",
  mrNo: "",
  ref_reg_patient: "",
  visitNo: "",
  opdNo: "",
  opdDate: null, // dayjs().format("YYYY/MM/DD"),
  validDays: "7",
  validUpto: dayjs().add(7, "day"), // dayjs().format("YYYY/MM/DD"),
  appointmentType: "0", //like: General, Emergency,
  depttName: null, //here used null becouse of AutoComplete Control
  depttAnnualNo: "",
  consultant: null, //here used null becouse of AutoComplete Control
  staffId: "",
  tokenNo: "",
  roomNo: null, //here used null becouse of AutoComplete Control
  unitName: "Main-Unit", //here used null becouse of AutoComplete Control

  rate: "0",
  discPer: "0",
  discAmt: "0",
  paidRs: "0",

  paymentMode: "Cash", //here used null becouse of AutoComplete Control
  zeroBilling: false,
  staffUN: "",
  ip: "",
};

export default function OpdRegn() {
  const { staffUN, ip, isEditRight, isDeleteRight } = useGlobalContext();
  // const dispatch = useDispatch();
  // const hookOPDData = useSelector((state) => state.OPD_Reducer.list);

  const [hid] = useState(3);

  const [loading, setLoading] = useState(false);

  // const [originalReportData, setOriginalReportData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [reportDataForRightTable, setreportDataForRightTable] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [rateSetup, setRateSetup] = useState("");

  const [appointmentTypeValue, setAppointmentTypeValue] = useState([]);
  const [deptt, setDeptt] = useState([]);
  const [unitName, setUnitName] = useState([]);
  const [roomNo, setRoomNo] = useState([]);

  // console.log("state =====> ", state);
  // return });  //GET_OPD_LIST_BY_MRNO

  // const data = getPatientsList_Regn();
  // const [regnListLeft, setRegnListLeft] = useState([]);

  const [regnValues, setRegnValues] = useState(initialValuesRegn);
  const [mrNoValue, setMRNoValue] = useState("");
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = React.useState(false);

  // const [selectedValue, setSelectedValue] = React.useState({});
  const [notify, setNotify] = React.useState({ isOpen: false, message: "", type: "" });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    isConfirm: null,
    title: "title",
    subtitle: "subtitle",
  });

  const [printForm, setPrintForm] = useState([]);
  const [isPrintDirect, setIsPrintDirect] = React.useState(false);
  const [mrNoValueForReport, setMRNoValueForReport] = useState("");
  const [openPopupReceipt, setOpenPopupReceipt] = React.useState(false);
  const [consultant, setConsultant] = useState([]);
  const [paymentMode, setPaymentMode] = useState([]);

  const getPaymentMode = async () => {
    const rrr = await GenServices.getEntityInnnerData(3, "PaymentMode");
    // console.log("PaymentMode List ------>", rrr);
    const res = rrr.map((item) => item.text);
    setPaymentMode(res);
    // console.log("PaymentMode List Res------>", res);
    // return rrr;
  };

  const getAppointmentType = async () => {
    const rrr = await GenServices.getEntityInnnerData(3, "AppointmentType");
    console.log("AppointmentType ------", rrr);
    const R1 = await rrr.map((item) => {
      return { val: item.val, text: item.text + " (Rate: " + item.rate + ")", rate: item.rate };
    });
    setAppointmentTypeValue(R1);
    // return rrr;
  };

  const getDepartment = async () => {
    const rrr = await GenServices.getEntityInnnerData(3, "Department");
    //console.log("Department ------", rrr);
    setDeptt(rrr);
    // return rrr;
  };

  const getHospitalUnit = async () => {
    const rrr = await GenServices.getEntityInnnerData(3, "HospitalUnit");
    // console.log("HospitalUnit ------", rrr);
    setUnitName(rrr);
    // return rrr;
  };

  const getRoomNo = async () => {
    const rrr = await GenServices.getEntityInnnerData(3, "OpdRoomNo");
    //console.log("OpdRoomNo ------", rrr);
    setRoomNo(rrr);
    // return rrr;
  };

  const getConsultants = async () => {
    // const rrr = await GenServices.getConsultants(hid);
    //console.log("getConsultants ------", rrr);
    // setConsultant(rrr);

    try {
      const res = await axios.get(`/staff/getconsultantswithdepartment/?hid=${hid}`);
      // console.log("getConsultant res.data AAAAAA ===>", res.data);
      //   console.log("getRecord by appointmentType  ===>", res.data);
      //   console.log("getRecord by res.status  ===>", res.status);
      if (res.data) {
        // console.log("getConsultant res.data ===>", res.data);
        const result = res.data.map((item) => {
          return { val: item.staffId, text: item.name, deptt: item.deptt };
        });
        // return result;
        setConsultant(result);
      }
      // setLoading(false);
      //return [];
    } catch (error) {
      console.error("getConsultant ===> Error: ", error);
      //return [];
    }
  };

  const setConsultantValue = (e) => {
    //if(e.target.value){
    if (e.target.value) {
      setFormValues((preValues) => {
        return { ...preValues, consultant: e.target.value.text };
      });
      setFormValues((preValues) => {
        return { ...preValues, staffId: e.target.value.val };
      });

      let depttList = consultant.find((element) => element.val == e.target.value.val).deptt;

      //console.log("deptt List =======> ", depttList);

      // let finalDepttList = depttList.map((option) => ({ val: option, text: option }));
      let finalDepttList = depttList.map((option) => option);
      // console.log("finalDepttList List =======> ", finalDepttList);
      setDeptt(finalDepttList);
      // setDeptt((preValues) => {
      //   return {
      //     ...preValues,
      //     paidRs: appointmentTypeValue.find((element) => element.val == e.target.value).rate,
      //   };
      // });
    }
  };

  useEffect(() => {
    // getPatientsList_Regn();
    getPatientsList_OPD();
    getAppointmentType();
    //getDepartment();
    getHospitalUnit();
    getRoomNo();
    getConsultants();
    getPaymentMode();
  }, []);

  //Form Start
  //*************************************************************************************************** */

  const validateRegn = (fieldValues = regnValues) => {
    let temp = { ...errors };
    // console.log("fieldValues =====>", fieldValues);
    if ("mrNo" in fieldValues) temp.mrNo = fieldValues.mrNo ? "" : "required.";
    if ("name" in fieldValues) temp.name = fieldValues.name ? "" : "required.";
    // if ("fhName" in fieldValues) temp.fhName = fieldValues.fhName ? "" : "Field is required.";
    setErrors({
      ...temp,
    });

    if ((fieldValues = formValues)) return Object.values(temp).every((x) => x == "");
  };

  const validateForm = (fieldValues = formValues) => {
    let temp = { ...errors };
    // console.log("fieldValues =====>", fieldValues);
    if ("appointmentType" in fieldValues) temp.appointmentType = fieldValues.appointmentType !== "0" ? "" : "required.";
    // if ("validDays" in fieldValues) temp.validDays = parseInt(fieldValues.validDays) >= 0 ? "" : "required.";
    // if ("validUpto" in fieldValues) temp.validUpto = dayjs(fieldValues.validUpto).isValid() ? "" : "Valid-Upto is required.";
    if ("depttName" in fieldValues) {
      temp.depttName = fieldValues.depttName !== "" && fieldValues.depttName !== null ? "" : "required.";
    }
    // if ("consultant" in fieldValues) {
    //   temp.consultant =
    //     fieldValues.consultant !== "" && fieldValues.consultant !== null ? "" : "Consultant is required.";
    // }
    if ("roomNo" in fieldValues) {
      temp.roomNo = fieldValues.roomNo !== "" && fieldValues.roomNo !== null ? "" : "required.";
    }
    if ("unitName" in fieldValues) {
      temp.unitName = fieldValues.unitName !== "" && fieldValues.unitName !== null ? "" : "required.";
    }

    if ("rate" in fieldValues) temp.rate = parseInt(fieldValues.rate) >= 0 ? "" : "required.";
    if ("discPer" in fieldValues) temp.discPer = parseInt(fieldValues.discPer) >= 0 ? "" : "required.";
    if ("discAmt" in fieldValues) temp.discAmt = parseInt(fieldValues.discAmt) >= 0 ? "" : "required.";
    if ("paidRs" in fieldValues) temp.paidRs = parseInt(fieldValues.paidRs) >= 0 ? "" : "required.";

    if ("paymentMode" in fieldValues) {
      temp.paymentMode = fieldValues.paymentMode !== "" && fieldValues.paymentMode !== null ? "" : "required.";
    }

    setErrors({
      ...temp,
    });

    // console.log("temp===>", temp);
    //console.log("Full Data===>", formValues);
    if ((fieldValues = formValues)) return Object.values(temp).every((x) => x == "");
  };

  const { formValues, setFormValues, errors, setErrors, handleChange } = MyUseForm(
    initialValuesOpd,
    true,
    validateForm
  );

  // useEffect(() => {
  //   console.log("formValues ===", formValues);
  // }, [formValues]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateRegn()) {
      if (validateForm()) {
        if (formValues.staffId !== "") {
          // RegnServices.insertPatient(formValues);
          //console.log("Submited Click........", formValues);
          // resetForm();
          setLoading(true);
          addOrEdit(formValues);
        } else {
          setNotify({
            isOpen: true,
            message: "Please Select the Consultant!",
            type: "error",
          });
        }
      } else {
        setLoading(false);
        setNotify({
          isOpen: true,
          message: "Please enter the value in required fields!",
          type: "error",
        });
      }
    } else {
      setLoading(false);
      setNotify({
        isOpen: true,
        message: "Please enter the value in required fields!",
        type: "error",
      });
    }
  };

  const resetForm = () => {
    setFormValues(initialValuesOpd);
    setRegnValues(initialValuesRegn);
    setRecordForEdit(null);
    setErrors({});
    setNotify({ isOpen: false, message: "", type: "" });
    setMRNoValueForReport("");
    cancelSearch();
    setLoading(false);
    setreportDataForRightTable([]);
    setSearchVal("");
  };

  useEffect(() => {
    if (recordForEdit != null) {
      // console.log('aaaaaaaaaaaaa');
      setFormValues({
        ...recordForEdit,
      });
    }
  }, [recordForEdit]);

  //Form End
  //*************************************************************************************************** */

  // const getPatientsList_Regn = async () => {
  //   //setLoading(true);
  //   // validate(initialValues);
  //   try {
  //     const res = await axios.get("/regn/getWithoutOPD");
  //     //  console.log("getPatientsLis t_Regn  ===>", res.data);
  //     if (res) {
  //       // setRegnListLeft(res.data);
  //       // console.log("res.data ===>", res.data)
  //     }
  //     // setLoading(false);
  //   } catch (error) {
  //     // setLoading(false);
  //     setNotify({ isOpen: true, message: error.message, type: "error" });
  //   }
  //   //setLoading(false);
  // };

  const getPatientsList_OPD = async () => {
    //setLoading(true);
    // validate(initialValues);
    try {
      // const res = await axios.get("/opd"); //getAll

      //Below report also used in OPD-Report page
      const res = await axios.get(`/opd/report/?hid=${hid}&consultant=ALL&startdate=${dayjs()}&enddate=${dayjs()}`);
      // console.log("res opd data ===>", res.data);

      if (res) {
        setReportData(res.data);
        // setOriginalReportData(res.data);

        // const top10Row = await res.data.filter((item, index) => {
        //   if (index < 10) {
        //     return item;
        //   }
        // });
        // setreportDataForRightTable(top10Row);

        // dispatch(set_OPD_List(res.data));
        // console.log("res.data ===>", res.data)
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    //setLoading(false);
  };

  const getConsultantFormForPrintWhenSaved = async (id) => {
    setLoading(true);
    try {
      const res = await axios.get(`/opd/getconsultformforprint/${id}`);
      // console.log("res getconsultformforprint ===>", res.data);

      var dd = {};
      if (res.data) {
        res.data.map((row) => (dd = row));

        handlePrintConsultationForm(dd);

        // setIsPrintDirect(true);
        // setPrintForm(dd);
        // setOpenPopup(true);
      }
      // setLoading(false);
    } catch (error) {
      setLoading(false);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    setLoading(false);
  };

  const bindData = async () => {
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    // await getPatientsList_Regn();
    await getPatientsList_OPD();
    setLoading(false);
  };

  const addOrEdit = async (opdData) => {
    // const opdData = {...formValues};
    // console.log("opdData addOrEdit =========>", opdData);
    opdData.staffUN = staffUN;
    opdData.ip = ip;

    // const queryobj = { params: { hid: hid } }; //<=== this query only for testing
    if (opdData._id) {
      //This is for Update
      // RegnServices.updatePatient(opdData);
      try {
        // console.log("axios patch Start===>", opdData);
        const res = await axios.patch(`/opd/${opdData._id}`, opdData);
        // console.log("axios.patch response===>", res);
        if (res) {
          bindData();
          setNotify({ isOpen: true, message: "Updated Successfully", type: "success" });
          getDataByMRNo();
        }
      } catch (error) {
        console.error("error=====>", error);
        setLoading(false);
        setNotify({ isOpen: true, message: error.message, type: "error" });
      }
    } else {
      //This is for Create new
      try {
        // console.log("axios Insert Start===>", opdData);
        const res = await axios.post("/opd", opdData);
        // console.log("axios Insert response===>", res.data);

        if (res.data) {
          bindData();
          setNotify({ isOpen: true, message: "Saved Successfully", type: "success" });
          getDataByMRNo();
          // console.log("axios Insert getConsultantFormForPrintWhenSaved===>", res.data._id);
          await getConsultantFormForPrintWhenSaved(res.data._id);
        }
      } catch (error) {
        setLoading(false);
        console.error("error=====>", error);
        setNotify({ isOpen: true, message: error.message, type: "error" });
      }
    }
  };

  const onDelete = async (opdNo) => {
    //if (window.confirm("Do you want to delete this record?")) {
    try {
      // console.log("axios.patch===>", Patient);
      setConfirmDialog({ ...confirmDialog, isOpen: false });
      const res = await axios.delete(`/opd/delete-opd-records/?hid=${hid}&opdNo=${opdNo}`);
      if (res) {
        bindData();
        setNotify({ isOpen: true, message: "Deleted Successfully", type: "success" });
        getDataByMRNo();
      }
    } catch (error) {
      console.error("error=====>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
  };

  const handleClickDelete = (data) => {
    if (isDeleteRight) {
      setConfirmDialog({
        isOpen: true,
        title: "Are you sure to delete this record?",
        subtitle: "You can't undo this operation",
        isConfirm: () => {
          onDelete(data.row.opdNo);
        },
      });
    } else {
      setNotify({ isOpen: true, message: "you do not have the right to delete", type: "error" });
    }
  };

  // const columnsRegn = [
  //   //{ field: "regNo", headerName: "#", width: 90, filterable: false, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
  //   // { field: "name", headerName: "Full name", width: 150, editable: true },
  //   // { field: "_id", headerName: "_id", width: 20, hideable: true },
  //   {
  //     field: "actions",
  //     headerName: "Actions",
  //     width: 100,
  //     sortable: false,
  //     renderCell: (curItem) => {
  //       return (
  //         <>
  //           <Stack direction="row" spacing={1}>
  //             <Controls.Button
  //               variant="outlined"
  //               text={curItem.row.mrNo}
  //               color="primary"
  //               title="Create OPD"
  //               onClick={() => handleMRNoByLeftTable(curItem.row)}
  //             ></Controls.Button>
  //           </Stack>
  //         </>
  //       );
  //     },
  //   },
  //   { field: "name", headerName: "Full name", width: 200 },
  // ];

  const columnsOpdForRightTop = [
    //{ field: "regNo", headerName: "#", width: 90, filterable: false, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
    // { field: "name", headerName: "Full name", width: 150, editable: true },
    // { field: "_id", headerName: "_id", type: "number", width: 20, hideable: true },
    {
      field: "mrNo",
      headerName: "MRNo/OPD",
      width: 100,
      // valueGetter: (item) => item.row.mrNo,
      renderCell: (item) => (
        <div>
          <Typography>
            <Controls.Button
              text={item.row.mrNo}
              onClick={() => {
                setMRNoValue(item.row.mrNo);
                // setreportDataForRightTable([]);
                // getDataByMRNo();
              }}
              variant="outlined"
            />
          </Typography>
          <Typography color="textSecondary" sx={{ fontSize: 13 }}>
            {item.row.opdNo}
          </Typography>
        </div>
      ),
    },
    // { field: "opdNo", headerName: "OPD No.", width: 100 },
    {
      field: "opdDate",
      headerName: "OPD Date",
      width: 100,
      valueGetter: (item) => dayjs(item.row.opdDate).format("DD/MM/YYYY hh:mm a"),
    },
    {
      field: "name",
      headerName: "Name/FName",
      width: 150,
      // valueGetter: (item) => item.row.name,
      renderCell: (item) => (
        <div>
          <Typography>{item.row.name}</Typography>
          <Typography color="textSecondary" sx={{ fontSize: 12 }}>
            {item.row.fhName}
          </Typography>
        </div>
      ),
    },

    {
      field: "age",
      headerName: "Age/Sex",
      width: 100,
      // valueGetter: (item) => item.row.ref_reg_patient.age + "/" + item.row.ref_reg_patient.gender.slice(0, 1),
      // valueGetter: (item) => item.row.age + "(Y)/" + item.row.gender.slice(0, 1),
      renderCell: (item) => (
        <div>
          <Typography>{item.row.age + "/" + item.row.gender.slice(0, 1)}</Typography>
          <Typography color="textSecondary" sx={{ fontSize: 12 }}>
            {item.row.pMobNo}
          </Typography>
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      sortable: false,
      renderCell: (curItem) => {
        return (
          <>
            <Stack direction="row" spacing={1}>
              {/* <Controls.ActionButton color="primary" title="View OPD Form" onClick={() => handlePrintView(curItem.row)}>
                <VisibilityIcon />
              </Controls.ActionButton> */}
              <Controls.ActionButton
                color="primary"
                title="Print OPD Form"
                onClick={() => handlePrintConsultationForm(curItem.row)}
              >
                <PrintIcon />
              </Controls.ActionButton>
              <Controls.ActionButton
                color="primary"
                title="Print Receipt"
                onClick={() => handlePrintReceipt(curItem.row)}
              >
                <PrintIcon />
              </Controls.ActionButton>

              <Controls.ActionButton color="primary" title="Edit_TopRight" onClick={() => handleEditOPD(curItem.row)}>
                <ModeEditOutlineIcon />
              </Controls.ActionButton>

              <Controls.ActionButton color="red" title="Delete OPD + Bills + LabReports" onClick={() => handleClickDelete(curItem)}>
                <DeleteForeverIcon />
              </Controls.ActionButton>
            </Stack>
          </>
        );
      },
    },
    // { field: "consultant", headerName: "Consultant", width: 150 },
  ];

  const columnsOpdReport = [
    //{ field: "regNo", headerName: "#", width: 90, filterable: false, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
    // { field: "name", headerName: "Full name", width: 150, editable: true },
    // { field: "_id", headerName: "_id", type: "number", width: 20, hideable: true },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      sortable: false,
      renderCell: (curItem) => {
        return (
          <>
            <Stack direction="row" spacing={1}>
              {/* <Controls.ActionButton color="red" title="Delete" onClick={() => handleClickDelete(curItem)}>
                <DeleteForeverIcon />
              </Controls.ActionButton> */}
              <Controls.ActionButton
                color="primary"
                title="Edit_Bottom_Table"
                onClick={() => handleEditOPD(curItem.row)}
              >
                <ModeEditOutlineIcon />
              </Controls.ActionButton>

              {/* <Controls.ActionButton color="primary" title="View OPD Form" onClick={() => handlePrintView(curItem.row)}>
                <VisibilityIcon />
              </Controls.ActionButton> */}
              <Controls.ActionButton
                color="primary"
                title="Print OPD Form"
                onClick={() => handlePrintConsultationForm(curItem.row)}
              >
                <PrintIcon />
              </Controls.ActionButton>
              <Controls.ActionButton
                color="primary"
                title="Print Receipt"
                onClick={() => handlePrintReceipt(curItem.row)}
              >
                <PrintIcon />
              </Controls.ActionButton>
            </Stack>
          </>
        );
      },
    },
    {
      field: "mrNo",
      headerName: "MRNo",
      width: 80,
      valueGetter: (item) => item.row.mrNo,
    },
    { field: "opdNo", headerName: "OPD No.", width: 100 },
    {
      field: "opdDate",
      headerName: "OPD Date",
      width: 100,
      valueGetter: (item) => dayjs(item.row.opdDate).format("DD/MM/YYYY hh:mm a"),
    },
    {
      field: "name",
      headerName: "Name / FHName",
      width: 150,
      // valueGetter: (item) => item.row.name,
      renderCell: (item) => (
        <div>
          <Typography>{item.row.name}</Typography>
          <Typography color="textSecondary" sx={{ fontSize: 12 }}>
            {item.row.fhName}
          </Typography>
        </div>
      ),
    },
    {
      field: "age",
      headerName: "Age/Sex",
      width: 100,
      // valueGetter: (item) => item.row.ref_reg_patient.age + "/" + item.row.ref_reg_patient.gender.slice(0, 1),
      valueGetter: (item) => item.row.age + "/" + item.row.gender.slice(0, 1),
    },
    { field: "consultant", headerName: "Consultant", width: 150 },
    // { field: "gender", headerName: "Gender", width: 80 },
    // { field: "tokenNo", headerName: "TokenNo", width: 100 },
    // { field: "pMobNo", headerName: "Mobile No.", width: 120, valueGetter: (item) => item.row.ref_reg_patient.mobileNo.pMobNo },
    // {
    //   field: "Address",
    //   headerName: "Address",
    //   width: 200,
    //   valueGetter: (item) => `${item.row.ref_reg_patient.pAddress.address || ""}, ${item.row.ref_reg_patient.pAddress.city || ""}`,
    // },
    {
      field: "paidRs",
      headerName: "Rs/Mode",
      width: 100,
      valueGetter: (item) => item.row.paidRs + "/" + item.row.paymentMode,
    },
    // { field: "paymentMode", headerName: "PaymentMode", width: 80 },

    // {
    //   field: "roomNo",
    //   headerName: "Room/Token",
    //   width: 80,
    //   valueGetter: (item) => item.row.roomNo + "/" + item.row.tokenNo,
    // },

    { field: "visitNo", headerName: "Visit", width: 80 },
    { field: "depttName", headerName: "DepttName", width: 150 },
  ];

  const handleEditOPD = (data) => {
    if (isEditRight) {
      setMRNoValue(data.mrNo);
      setErrors({});
      setNotify(false);
      // console.log("AAAAAAAAAAAAAAAAAA==>", data);

      setRecordForEdit(data);
    } else {
      setNotify({ isOpen: true, message: "you do not have the right to edit!", type: "error" });
    }
  };

  useEffect(() => {
    getDataByMRNo();
  }, [recordForEdit]);

  useEffect(() => {
    if (recordForEdit != null) {
      // console.log("recordForEdit.mrNo", recordForEdit.mrNo);

      // setRegnValues({
      //   ...recordForEdit.ref_reg_patient,
      // });

      // const {}=

      setRegnValues({
        ...recordForEdit,
      });

      setFormValues({
        ...recordForEdit,
      });

      setMRNoValue(recordForEdit.mrNo);

      // console.log("useEffect recordForEdit ===>", recordForEdit);
    }
  }, [recordForEdit]);

  // const handleMRNoByLeftTable = (curItem) => {
  //   if (recordForEdit === null) {
  //     setErrors({});
  //     // console.log("handleMRNoByLeftTable curItem====>", curItem);
  //     setMRNoValue(curItem.mrNo);
  //     setRegnValues(curItem);
  //     // setFormValues((preValue) => { ...preValue, ref_reg_patient: curItem._id });
  //     setFormValues((preValues) => {
  //       return { ...preValues, ref_reg_patient: curItem._id };
  //     });
  //     setFormValues((preValues) => {
  //       return { ...preValues, mrNo: curItem.mrNo };
  //     });
  //     // getDataByMRNo();
  //   } else {
  //     setNotify({ isOpen: true, message: "Please reset the form!", type: "error" });
  //   }
  // };

  const getDataByMRNo = async () => {
    try {
      // if (recordForEdit === null) {
      if (mrNoValue) {
        // console.log("getDataByMRNo MRNo  ========> ", mrNoValue);
        setSearchVal("");

        const res = await axios.get(`/regn/find/${mrNoValue}`);
        // const res = await axios.get(`/regn/find/${mrNoValue}`);
        // console.log("Res  ========> ", res);
        if (res) {
          // const selectedOPD = originalR eportData.filter((item) => item.mrNo == mrNoValue);
          // console.log("selectedOPD  ========> ", selectedOPD);
          // setreportDataForRightTable(selectedOPD);
          if (res.data.length > 0) {
            setRegnValues(res.data[0]);
            setFormValues((preValues) => ({ ...preValues, ref_reg_patient: res.data[0]._id }));
            setFormValues((preValues) => ({ ...preValues, mrNo: res.data[0].mrNo }));
            getAllOPDDataOfOneMRNo();
          } else {
            setNotify({ isOpen: true, message: "Data not found!", type: "error" });
          }
        } else {
          setNotify({ isOpen: true, message: "Data not found!", type: "error" });
        }
      }
      // else {
      //   setNotify({ isOpen: true, message: "Please enter the MRNo!", type: "error" });
      // }
      // } else {
      //   setNotify({ isOpen: true, message: "Please reset the form!", type: "error" });
      // }
    } catch (error) {
      setNotify({ isOpen: true, message: error.message, type: "error" });
      setLoading(false);
    }
  };

  const getAllOPDDataOfOneMRNo = async () => {
    try {
      if (mrNoValue) {
        // console.log("getAllOPDDataOfOneMRNo MRNo  ========> ", mrNoValue);

        const res = await axios.get(`/opd/?hid=${hid}&mrNo=${mrNoValue}`);
        if (res.data) {
          // const selectedOPD = reportData.filter((item) => item.mrNo == mrNoValue);
          // console.log("setreportDataForRightTable  ========> ", res.data);
          setreportDataForRightTable(res.data);
        } else {
          setNotify({ isOpen: true, message: "Data not found!", type: "error" });
        }
      }
    } catch (error) {
      console.error("Error getAllOPDDataOfOneMRNo ====>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
      setLoading(false);
    }
  };

  const handleChangeMRNo = (e) => {
    if (recordForEdit === null) {
      setErrors({});
      setMRNoValue(e.target.value);
      setFormValues(initialValuesOpd);
      setRegnValues(initialValuesRegn);
      cancelSearch();
      setLoading(false);
      // setRecordForEdit(null);
      setreportDataForRightTable([]);
    } else {
      setNotify({ isOpen: true, message: "Please reset the form!", type: "error" });
    }
    // console.log(mrNoValue)
  };

  // const [print, setPrint]=  useState(false);
  // const [printContent, setPrintContent]=  useState(null);

  // <ReactToPrint
  //     trigger={print}
  //     content={printContent}
  //     documentTitle="This is print page title"
  //     pageStyle="print"
  //     ></ReactToPrint>

  const componentRef = useRef();

  const PrintComponent = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrintView = (item) => {
    // console.log("item ====>", item); ref={componentRef}
    setPrintForm(item);
    // useReactToPrint({
    setOpenPopup(true);
    //   content: () => componentRef.current,
    // });
  };

  const handlePrintConsultationForm = (item) => {
    // console.log("item ====>", item); //ref={componentRef}
    setIsPrintDirect(false);
    setPrintForm(item);
    setOpenPopup(true);
    // PrintComponent();
  };

  const handlePrintReceipt = (item) => {
    // console.log("handlePrintReceipt ====>", item); //ref={componentRef}
    setIsPrintDirect(false);
    setPrintForm(ConvertDataToPrintableReceipt(item));
    setOpenPopupReceipt(true);
  };

  const ConvertDataToPrintableReceipt = (item) => {
    // console.log("item ====>", item);
    // const { mrNo, name, gender, age } = item.ref_reg_patient;
    // const { pMobNo } = item.ref_reg_patient.mobileNo;
    const {
      hid,
      opdNo,
      opdDate: billDate,
      billNo,
      staffUN,
      consultant,
      staffId,
      rate,
      discAmt,
      paidRs,
      paymentMode,
      tblDataRows,
      mrNo,
      name,
      gender,
      age,
      pMobNo,
    } = item;

    const others = { feeHead: "OPD Consultation Charges" };

    const printData = {
      hid,
      mrNo,
      name,
      gender,
      age,
      pMobNo,
      opdNo,
      billDate,
      billNo,
      staffUN,
      consultant,
      staffId,
      rate,
      discAmt,
      paidRs,
      paymentMode,
      tblDataRows,
      ...others,
    };

    // console.log("printData ====>", printData);

    return printData;
  };

  // const handleChangeMRNoForReport = (e) => {
  //   // if (recordForEdit === null) {
  //   setErrors({});
  //   setMRNoValueForReport(e.target.value);

  //   // console.log(mrNoValue)
  // };

  let filterTimeout;

  const handleSearch = async (e) => {
    try {
      clearTimeout(filterTimeout);
      const val = e.target.value;
      setSearchVal(val);
      if (val.length > 1) {
        filterTimeout = setTimeout(async () => {
          const res = await axios.get(`/opd/searchopdregndata/?hid=${hid}&searchtext=${val}`);
          // console.log("RRRRRRRRRRRRRRRRR  ========> ", res);
          if (res.data) {
            // const selectedOPD = reportData.filter((item) => item.mrNo == mrNoValue);
            // console.log("selectedOPD  ========> ", selectedOPD);
            setreportDataForRightTable(res.data);
          } else {
            setNotify({ isOpen: true, message: "Data not found!", type: "error" });
          }
        }, 500);
      } else {
        setreportDataForRightTable([]);
      }
    } catch (error) {
      console.error("Error handleSearch ====>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
      setLoading(false);
    }

    // const str = val || "";
    // console.log("BindBottomTable Value========>", val);

    //if (val) {
    // const filteredRows = search(reportData, val);
    // console.log("filteredRows =>", filteredRows);
    // setReportData(filteredRows);
    //}
  };

  // const [fieldNamesForSearch] = useState(["name", "mrNo", "opdNo"]);
  //"mobileNo.pMobNo", pAddress.address
  // const [fieldNamesForSearch] = useState(["mobileNo"]); //"mobileNo.pMobNo",

  // function search(allItems, val) {
  //   return allItems.filter((item) => {
  //     console.log("Items", item);
  //     return fieldNamesForSearch.some((newItem) => {
  //       if (newItem === "name")
  //         return item.ref_reg_patient.name.toString().toLowerCase().indexOf(val.toLowerCase()) > -1;
  //       // else if (newItem === "pAddress")
  //       //   return item.pAddress.address.toString().toLowerCase().indexOf(val.toLowerCase()) > -1;
  //       else return item[newItem].toString().toLowerCase().indexOf(val.toLowerCase()) > -1;
  //     });
  //   });
  // }

  const cancelSearch = () => {
    setreportDataForRightTable([]);
    setSearchVal("");
    // setReportData(reportData);
  };

  const handleChangeRate = (e) => {
    const { name, value } = e.target;

    // console.log("e.target.value ======= ", value);

    if (!value) value = 0;

    let rate = 0;
    let discPer = 0;
    let discAmt = 0;
    let paidRs = 0;

    if (name == "rate") {
      rate = parseFloat(value);
      // alert('this is rate')
      discPer = parseFloat(formValues.discPer);

      if (rate < 1) {
        setFormValues((prevValue) => ({ ...prevValue, discPer: 0 }));
        discPer = 0;
        rate = 0;
      }

      discAmt = (rate * discPer) / 100;
      paidRs = rate - discAmt;
    } 
    else if (name == "discPer") {
      discPer = parseFloat(value);
      // alert('this is discPer')
      if (discPer <= -1) {
        setFormValues((prevValue) => ({ ...prevValue, discPer: 0 }));
        discPer = 0;
      }

      rate = parseFloat(formValues.rate);
      discAmt = (rate * discPer) / 100;
      paidRs = rate - discAmt;
    } 
    else if (name == "discAmt") {
      discAmt = parseFloat(value);
      
      rate = parseFloat(formValues.rate);

      discPer = ((parseFloat(value) / rate) * 100).toFixed(2);

      //discAmt = (rate * discPer) / 100;
      paidRs = rate - discAmt;
    }

    setFormValues((prevValue) => ({ ...prevValue, paidRs: paidRs }));
    setFormValues((prevValue) => ({ ...prevValue, rate: rate }));
    setFormValues((prevValue) => ({ ...prevValue, discPer: discPer }));
    setFormValues((prevValue) => ({ ...prevValue, discAmt: discAmt }));

    setRateSetup(Math.random());
    // setTimeout(()=>{
    // },200);
  };

  useEffect(() => {
    if (rateSetup) {
      validateForm();
    }
  }, [rateSetup]);

  return (
    <>
      <PageHeader title="Patient OPD Entry" icon={<PeopleOutlineRoundedIcon fontSize="large" color="primary" />} />
      {/* <ReactToPrint
        trigger={() => <button>Print this out!</button>}
        content={() => componentRef.current}
      /> */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {/* <Typography variant="h6" color="inherit" noWrap sx={{ pb: 1 }}>
            OPD Entry
          </Typography> */}

          <MyForm onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {/* mrNo */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Controls.Input
                  label="MRNo"
                  name="mrNo"
                  value={mrNoValue}
                  onChange={handleChangeMRNo}
                  title="press tab key for get record"
                  onKeyDown={(e) => (e.key === "Tab" ? getDataByMRNo() : "")}
                  error={errors.mrNo}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setMRNoValue("");
                            setreportDataForRightTable([]);
                          }}
                          color="primary"
                          sx={{ p: "10px" }}
                          aria-label="directions"
                        >
                          <ClearIcon />
                        </IconButton>
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <IconButton onClick={getDataByMRNo} color="primary" sx={{ p: "10px" }} aria-label="directions">
                          <DirectionsIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {/* Name */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Controls.Input
                  label="Patient Name"
                  name="name"
                  value={regnValues.name}
                  // onChange={handleChange}
                  error={errors.name}
                  // style={{background: "rgb(232, 241, 250)"}}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid>
              {/* Father/MotherName */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Controls.Input
                  label="Father/Husband Name"
                  name="fhName"
                  value={regnValues.fhName}
                  // onChange={handleChange}
                  error={errors.fhName}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{regnValues.soDo}</InputAdornment>,
                  }}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid>
              {/* Gender/Age */}
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <Controls.Input
                  label="Gender/Age"
                  name="gender"
                  value={regnValues.gender + " / " + regnValues.age}
                  // onChange={handleChange}
                  // options={GenServices.getGender()}
                  // error={errors.gender}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid>
              {/* disease */}
              {/* <Grid item sm={6} md={3}>
                <Controls.Input
                  label="Disease"
                  name="disease"
                  value={regnValues.disease}
                  // onChange={handleChange}
                  // error={errors.disease}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid> */}
              {/* Email */}
              {/* <Grid item sm={6} md={3}>
                <Controls.Input
                  label="Email"
                  name="email"
                  value={regnValues.email}
                  // onChange={handleChange} error={errors.email}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid> */}
              {/* mobileNo.pMobNo */}
              {/* <Grid item sm={6} md={3}>
                <Controls.Input
                  label="Patient Mobile Number"
                  name="mobileNo.pMobNo"
                  value={regnValues.mobileNo.pMobNo}
                  // onChange={handleChange}
                  // error={errors.pMobNo}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid> */}

              {/* unitName */}
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <Controls.Autocomplete
                  label="Branch Name"
                  name="unitName"
                  value={formValues.unitName}
                  onChange={handleChange}
                  options={unitName.map((option) => option.text)}
                  error={errors.unitName}
                />
              </Grid>

              {/* <Divider sx={{ width: "100%", height: 10, m: 0.5 }} orientation="horizontal" /> */}

              {/* consultant */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Controls.Autocomplete
                  label="Consultant"
                  name="consultant"
                  value={formValues.consultant}
                  // value={formValues.staffId}
                  onChange={(e) => {
                    // handleChange(e);
                    setConsultantValue(e);
                  }}
                  // onChange={handleChange}
                  // options={consultant.map((option) => option.text)}
                  options={consultant}
                  // error={errors.consultant}
                />
              </Grid>

              {/* depttName */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                {/* <Controls.DropdownList */}
                <Controls.Autocomplete
                  label="Department Name"
                  name="depttName"
                  value={formValues.depttName}
                  onChange={handleChange}
                  // options={deptt.map((option) => ({val:option.text, text:option.text}))}
                  options={deptt}
                  error={errors.depttName}
                />
              </Grid>

              {/* validDays */}
              {/* <Grid item sm={6} md={2}>
                <Controls.Input
                  label="Valid-Days"
                  type="number"
                  name="validDays"
                  value={formValues.validDays}
                  // onChange={handleChange}
                  onChange={(e) => {
                    handleChange(e);
                    // setFormValues((preValues) => {
                    //   return { ...preValues, [e.target.name]: e.target.value };
                    // });

                    setFormValues((preValues) => {
                      return { ...preValues, validUpto: dayjs().add(e.target.value, "day") };
                    });
                  }}
                  error={errors.validDays}
                />
              </Grid> */}

              {/* validUpto */}
              {/* <Grid item sm={6} md={3}>
                <Controls.DatePicker
                  label="Valid-Upto"
                  name="validUpto"
                  value={formValues.validUpto}
                  // onChange={handleChange}
                  // error={errors.validUpto}
                />
              </Grid> */}

              {/* appointmentType */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Controls.DropdownList
                  label="AppointmentType/Case"
                  name="appointmentType"
                  value={formValues.appointmentType}
                  //handleChange
                  onChange={(e) => {
                    handleChange(e);
                    // setFormValues((preValues) => {
                    //   return { ...preValues, [e.target.name]: e.target.value };
                    // });
                    setFormValues((preValues) => {
                      return {
                        ...preValues,
                        paidRs: appointmentTypeValue.find((element) => element.val == e.target.value).rate,
                      };
                    });

                    setFormValues((preValues) => {
                      return {
                        ...preValues,
                        rate: appointmentTypeValue.find((element) => element.val == e.target.value).rate,
                      };
                    });
                    setFormValues((preValues) => {
                      return {
                        ...preValues,
                        discPer: "0",
                      };
                    });
                    setFormValues((preValues) => {
                      return {
                        ...preValues,
                        discAmt: "0",
                      };
                    });

                    if (appointmentTypeValue.find((element) => element.val == e.target.value).rate === 0) {
                      setFormValues((preValues) => {
                        return { ...preValues, zeroBilling: true };
                      });
                    } else {
                      setFormValues((preValues) => {
                        return { ...preValues, zeroBilling: false };
                      });
                    }
                  }}
                  options={appointmentTypeValue}
                  // defaultValue="0" defaultText="AAAAAAAAA"
                  error={errors.appointmentType}
                />
              </Grid>

              {/* RoomNo */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Controls.Autocomplete
                  label="Room-No."
                  name="roomNo"
                  value={formValues.roomNo}
                  onChange={handleChange}
                  options={roomNo.map((option) => option.text)}
                  error={errors.roomNo}
                />
              </Grid>
              {/* tokenNo */}
              {/* <Grid item sm={3} md={2}>
                <Controls.Input
                  label="TokenNo"
                  name="tokenNo"
                  value={formValues.tokenNo}
                  onChange={handleChange}
                  error={errors.tokenNo}
                />
              </Grid> */}

              {/* rate */}
              <Grid item xs={6} sm={6} md={4} lg={4}>
                <Controls.Input
                  label="Rate"
                  name="rate"
                  value={formValues.rate}
                  onChange={handleChangeRate}
                  error={errors.rate}
                  type="number"
                />
              </Grid>
              {/* discPer */}
              <Grid item xs={6} sm={6} md={4} lg={4}>
                <Controls.Input
                  label="Disc%"
                  name="discPer"
                  value={formValues.discPer}
                  onChange={handleChangeRate}
                  error={errors.discPer}
                  type="number"
                  disabled={isEditRight === true ? false : true}
                />
              </Grid>
              {/* discAmt */}
              <Grid item xs={6} sm={6} md={4} lg={4}>
                <Controls.Input
                  label="Disc-Amt"
                  name="discAmt"
                  value={formValues.discAmt}
                  onChange={handleChangeRate}
                  error={errors.discAmt}
                  type="number"
                  disabled={isEditRight === true ? false : true}
                />
              </Grid>

              {/* paidRs */}
              <Grid item xs={6} sm={6} md={4} lg={4}>
                <Controls.Input
                  label="Paid Rs."
                  name="paidRs"
                  value={formValues.paidRs}
                  // onChange={handleChangeRate}
                  error={errors.paidRs}
                  type="number"
                />
              </Grid>

              {/* paymentMode */}
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Controls.Autocomplete
                  label="Payment-Mode"
                  name="paymentMode"
                  value={formValues.paymentMode}
                  onChange={handleChange}
                  options={paymentMode}
                  error={errors.paymentMode}
                />
              </Grid>

              {/* zeroBilling */}
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Controls.Checkbox
                  label="Zero-Billing"
                  name="zeroBilling"
                  value={formValues.zeroBilling}
                  onChange={handleChange}
                  // error={errors.zeroBilling}
                />
              </Grid>

              {/* Button */}
              <Grid item sm={12} alignContent="center">
                <Box m={1} display="flex">
                  <span>
                    <Controls.Button
                      text="Reset"
                      onClick={resetForm}
                      //variant="outlined"
                      startIcon={<RestartAltIcon />}
                      // color="default"
                      // sx={{ flexGrow: 0 }}
                    />
                  </span>
                  <span style={{ mx: "10px", flexGrow: 1, textAlign: "center" }}>
                    <Controls.Button
                      sx={{ mx: "10px" }}
                      text="Save and Print"
                      //onClick={() => console.log(formValues)}
                      //variant="outlined"
                      disabled={loading}
                      type="submit"
                      startIcon={<SaveRoundedIcon />}
                    />
                    <ClipLoader loading={loading} color="#36d7b7" speedMultiplier={1} />
                  </span>
                </Box>
              </Grid>

              {/* <Grid item sm={12}>
          <FormControlLabel control={<Checkbox color="secondary" name="saveCard" value="yes" />}
            label="Remember credit card details for next time"
          />
        </Grid> */}

              {/* <Grid item sm={12} md={3}>
          <FormGroup row>  
            
            <TextField label="Test-Name" variant="standard" placeholder="username" />
            </FormGroup>
        </Grid> */}
            </Grid>
          </MyForm>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Controls.Input
                label="Searching by MRNo, OPDNo, Name, FHName, MobNo"
                name="search"
                value={searchVal}
                onChange={handleSearch}
                // title="press tab key for get record"
                // onKeyDown={(e) => (e.key === "Tab" ? getDataByMRNo() : "")}
                // error={errors.mrNo}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={cancelSearch} color="primary" sx={{ p: "10px" }} aria-label="directions">
                        <ClearIcon />
                      </IconButton>
                      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                      <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions">
                        <SearchIcon />
                        {/* <DirectionsIcon /> */}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <DataGrid
            // heading="Patient OPD Records for Right Table"
            loading={loading}
            columns={columnsOpdForRightTop}
            rows={reportDataForRightTable}
            // showToolbar={false}
            pageSize={40}
            autoPageSize
            boxHeight={350}
          />

          {/* Registration Record */}
          {/* <DataGrid
            heading="Registered Patient"
            loading={loading}
            columns={columnsRegn}
            rows={regnListLeft}
            showToolbar={false}
            margin="dense"
          /> */}
        </Grid>
      </Grid>
      <br></br>
      {/* OPD Record */}
      <DataGrid
        // heading="Patient OPD Records"
        loading={loading}
        columns={columnsOpdReport}
        rows={reportData}
        // showToolbar={false}
        pageSize={40}
        boxHeight={680}
      />
      <Popup title="Consultation Form" openPopup={openPopup} setOpenPopup={setOpenPopup} isCloseOutside={true}>
        {/* <RegistrationForm addOrEdit={addOrEdit} recordForEdit={recordForEdit} /> */}
        {/* <OpdRegnFormPrint printForm={printForm} /> */}
        <OpdRegnFormPrint {...printForm} isPrintDirect={isPrintDirect} setIsPrintDirect={setIsPrintDirect} />
      </Popup>
      <Popup
        title="OPD Consultation Charges"
        openPopup={openPopupReceipt}
        setOpenPopup={setOpenPopupReceipt}
        isCloseOutside={true}
      >
        {/* <RegistrationForm addOrEdit={addOrEdit} recordForEdit={recordForEdit} /> */}
        {/* <OpdRegnFormPrint printForm={printForm} /> */}
        <DirectBillPrint
          // billHeading="OPD Bill - Cum - Receipt"
          billHeading="Receipt"
          feeHead="OPD Consultation Charges"
          {...printForm}
          isPrintDirect={isPrintDirect}
          setIsPrintDirect={setIsPrintDirect}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      Note: The above table will show only today's records.
    </>
  );

  // {
  //   field: "fullName",
  //   headerName: "Full name",
  //   description: "This column has a value getter and is not sortable.",
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params) =>
  //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  // },
}
